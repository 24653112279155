<template>
  <div>
    <b-custom-spinner
      style="
        z-index: 999;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        overflow-y: hidden;
      "
      :is_progress="is_progress"
    ></b-custom-spinner>

    <b-tabs
      lazy
      pills
      active-nav-item-class="bg-light-primary border-0 shadow-none cursor-pointer"
    >
      <b-tab title="Pano">
        <b-card>
          <b-row>
            <b-col cols="2">
              <span>{{ lang("t_date") }}</span>
              <v-select
                class="mt-50"
                v-model="selected_date_filter_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="date_filter_types"
                @input="RefreshDataToShow"
              />
            </b-col>
            <b-col>
              <span>{{ lang("t_queue") }}</span>
              <v-select
                appendToBody
                class="mt-50"
                v-model="selected_merged_queues"
                :reduce="(option) => option"
                label="display_name"
                value="internal_name"
                :options="merged_queues"
                :placeholder="lang('t_queue')"
                multiple
                deselectFromDropdown
                :closeOnSelect="false"
              >
                <template #option="{ internal_name, _type }">
                  <div class="d-flex align-items-center"></div>
                  <span v-if="_type == 'queue'" class="" style="margin: 0">{{
                    merged_queues.find((e) => e.internal_name == internal_name)
                      .display_name
                  }}</span>
                  <span
                    v-b-tooltip.hover.bottom="
                      queue_groups
                        .find((e) => e.internal_name)
                        .queues.map((e) => e.display_name)
                        .join(' - ')
                    "
                    v-else
                    class="font-weight-bolder"
                    style="margin: 0"
                    >{{
                      merged_queues.find(
                        (e) => e.internal_name == internal_name
                      ).display_name
                    }}</span
                  >
                  <small v-if="_type == 'queue_group'" class="text-muted ml-1">
                    {{ lang(`t_queueGroup`) }}</small
                  >
                </template>
                <template #list-header>
                  <div class="my-50 mx-50">
                    <b-button
                      class="w-50"
                      size="sm"
                      variant="flat-secondary"
                      @click="
                        () => {
                          selected_merged_queues = merged_queues;
                        }
                      "
                    >
                      <feather-icon size="14" icon="CheckCircleIcon" />
                      {{ lang("t_selectAll") }}
                    </b-button>
                    <b-button
                      class="w-50"
                      size="sm"
                      variant="flat-secondary"
                      @click="
                        () => {
                          selected_merged_queues = [];
                        }
                      "
                    >
                      <feather-icon size="14" icon="XOctagonIcon" />
                      {{ lang("t_clear") }}
                    </b-button>
                  </div>
                </template>
              </v-select>
            </b-col>
          </b-row>
        </b-card>

        <b-row class="mt-1 d-flex align-items-stretch">
          <b-col cols="5">
            <b-card no-body class="card-developer-meetup">
              <div class="bg-light-primary border rounded-top text-center">
                <b-row>
                  <b-col
                    class="border-right d-flex align-items-center justify-content-center"
                  >
                    <feather-icon
                      class="text-primary my-1"
                      size="28"
                      icon="HeadphonesIcon"
                    ></feather-icon>
                    <span class="ml-1">Temsilci</span>
                  </b-col>
                  <b-col
                    class="border-left d-flex align-items-center justify-content-center"
                  >
                    <feather-icon
                      class="text-primary my-1"
                      size="28"
                      icon="UsersIcon"
                    />
                    <span class="ml-1">Müşteri</span>
                  </b-col>
                </b-row>
              </div>
              <b-card-body>
                <b-row class="mx-0 mt-1 text-center">
                  <b-col class="d-flex align-items-center justify-content-end">
                    <h2 class="my-0 mx-1">
                      {{
                        parseFloat(DataToShow.avgagentspeech || 0).toFixed(1)
                      }}
                    </h2>
                    <feather-icon
                      :class="
                        DataToShow.avgagentspeech > 50
                          ? 'text-success'
                          : 'text-danger'
                      "
                      :icon="
                        DataToShow.avgagentspeech > 50
                          ? 'ArrowUpIcon'
                          : 'ArrowDownIcon'
                      "
                    >
                    </feather-icon>
                  </b-col>

                  <b-col
                    class="d-flex align-items-center justify-content-center"
                  >
                    <span>Konuşma Oranı %</span>
                  </b-col>
                  <b-col
                    class="d-flex align-items-center justify-content-start"
                  >
                    <feather-icon
                      :class="
                        DataToShow.avgcustomerspeech > 50
                          ? 'text-success'
                          : 'text-danger'
                      "
                      :icon="
                        DataToShow.avgcustomerspeech > 50
                          ? 'ArrowUpIcon'
                          : 'ArrowDownIcon'
                      "
                    >
                    </feather-icon>
                    <h2 class="my-0 mx-1">
                      {{
                        parseFloat(DataToShow.avgcustomerspeech || 0).toFixed(1)
                      }}
                    </h2>
                  </b-col>
                </b-row>

                <b-row class="mx-0 mt-1 text-center">
                  <b-col class="d-flex align-items-center justify-content-end">
                    <!-- <feather-icon size="20" icon="SmileIcon"></feather-icon> -->

                    <h2 class="my-0 mx-1">
                      {{
                        parseFloat(DataToShow.avgagentsentiment || 0).toFixed(1)
                      }}
                    </h2>
                    <feather-icon
                      :class="
                        DataToShow.avgagentsentiment > 50
                          ? 'text-success'
                          : 'text-danger'
                      "
                      :icon="
                        DataToShow.avgagentsentiment > 50
                          ? 'ArrowUpIcon'
                          : 'ArrowDownIcon'
                      "
                    >
                    </feather-icon>
                  </b-col>

                  <b-col
                    class="d-flex align-items-center justify-content-center"
                  >
                    <span>Memnuniyet %</span>
                  </b-col>
                  <b-col
                    class="d-flex align-items-center justify-content-start"
                  >
                    <feather-icon
                      :class="
                        DataToShow.avgcustomersentiment > 50
                          ? 'text-success'
                          : 'text-danger'
                      "
                      :icon="
                        DataToShow.avgcustomersentiment > 50
                          ? 'ArrowUpIcon'
                          : 'ArrowDownIcon'
                      "
                    >
                    </feather-icon>
                    <h2 class="my-0 mx-1">
                      {{
                        parseFloat(
                          DataToShow.avgcustomersentiment || 0
                        ).toFixed(1)
                      }}
                    </h2>
                    <!-- <feather-icon size="20" icon="MehIcon"></feather-icon> -->
                  </b-col>
                </b-row>

                <b-row class="mx-0 mt-1 text-center">
                  <b-col class="d-flex align-items-center justify-content-end">
                    <!-- <feather-icon size="20" icon="Volume2Icon"></feather-icon> -->

                    <h2 class="my-0 mx-1">
                      {{ parseFloat(DataToShow.avgagentwords || 0).toFixed(1) }}
                    </h2>
                    <feather-icon
                      :class="
                        DataToShow.avgagentwords > 50
                          ? 'text-success'
                          : 'text-danger'
                      "
                      :icon="
                        DataToShow.avgagentwords > 50
                          ? 'ArrowUpIcon'
                          : 'ArrowDownIcon'
                      "
                    >
                    </feather-icon>
                  </b-col>

                  <b-col
                    class="d-flex align-items-center justify-content-center"
                  >
                    <span>Konuşma Hızı %</span>
                  </b-col>
                  <b-col
                    class="d-flex align-items-center justify-content-start"
                  >
                    <feather-icon
                      :class="
                        DataToShow.avgcustomerwords > 50
                          ? 'text-success'
                          : 'text-danger'
                      "
                      :icon="
                        DataToShow.avgcustomerwords > 50
                          ? 'ArrowUpIcon'
                          : 'ArrowDownIcon'
                      "
                    >
                    </feather-icon>
                    <h2 class="my-0 mx-1">
                      {{
                        parseFloat(DataToShow.avgcustomerwords || 0).toFixed(1)
                      }}
                    </h2>
                    <!-- <feather-icon size="20" icon="VolumeIcon"></feather-icon> -->
                  </b-col>
                </b-row>

                <!-- metting header -->
                <!-- <div class="meetup-header d-flex align-items-center">
              <div class="meetup-day">
                <h6 class="mb-0">THU</h6>
                <h3 class="mb-0">24</h3>
              </div>
              <div class="my-auto">
                <b-card-title class="mb-25"> Developer Meetup </b-card-title>
                <b-card-text class="mb-0">
                  Meet world popular developers
                </b-card-text>
              </div>
            </div> -->
                <!--/ metting header -->

                <!-- media -->
                <!-- <b-media v-for="media in mediaData" :key="media.avatar" no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="34">
                  <feather-icon :icon="media.avatar" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">
                  {{ media.title }}
                </h6>
                <small>{{ media.subtitle }}</small>
              </b-media-body>
            </b-media> -->
              </b-card-body>
            </b-card>
          </b-col>
          <b-col cols="7">
            <b-card no-body class="card-statistics">
              <!-- <b-card-header>
                <b-card-title>
                  <h4 class="font-weight-bolder mb-0">
                    {{ "Konuşma Detayları" }}
                  </h4></b-card-title
                >
              </b-card-header> -->
              <b-card-body class="pb-0 align-items-center pt-1 pb-2">
                <b-row>
                  <b-col
                    v-for="item in statisticsItems2"
                    :key="item.icon"
                    md="4"
                    sm="6"
                    class="mt-auto mb-2 mb-md-0"
                    :class="item.customClass"
                  >
                    <b-media class="mt-2 mb-75" no-body>
                      <b-media-aside class="mr-2">
                        <b-avatar rounded="lg" size="64" :variant="item.color">
                          <feather-icon size="28" :icon="item.icon" />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h2 class="font-weight-bolder mb-0">
                          {{ item.title }}
                        </h2>
                        <b-card-text class="font-small-3 mb-0">
                          {{ item.subtitle }}
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    v-for="item in statisticsItems"
                    :key="item.icon"
                    md="4"
                    sm="6"
                    class="mt-auto mb-2 mb-md-0"
                    :class="item.customClass"
                  >
                    <b-media class="mt-2 mb-75" no-body>
                      <b-media-aside class="mr-2">
                        <b-avatar rounded="lg" size="64" :variant="item.color">
                          <feather-icon size="28" :icon="item.icon" />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h2 class="font-weight-bolder mb-0">
                          {{ item.title }}
                        </h2>
                        <b-card-text class="font-small-3 mb-0">
                          {{ item.subtitle }}
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card>
              <div class="d-flex align-items-center">
                <b-card-title>
                  {{ lang("t_keywordCategories") }}
                </b-card-title>
                <b-button
                  v-b-modal.modal-key-word
                  class="px-50 py-50 ml-auto"
                  variant="flat-secondary"
                >
                  <i class="bi bi-pencil font-medium-1"></i>
                </b-button>
              </div>

              <b-row>
                <b-col>
                  <swiper
                    class="swiper-autoplay"
                    :options="swiperOptions"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  >
                    <swiper-slide
                      style="overflow: hidden"
                      v-for="(data, index) in DataToShow.Categories"
                      :key="index"
                    >
                      <b-card
                        :class="
                          data.Name == selected_keyword_category
                            ? 'border-primary'
                            : ''
                        "
                        @click="onClickKeywordCategory(data)"
                        class="border rounded-lg mb-0 cursor-pointer"
                        no-body
                      >
                        <b-card-body class="pb-0">
                          <div class="truncate">
                            <h2 class="mb-25 font-weight-bolder">
                              {{ data.count }}
                            </h2>
                            <span>{{ data.Name }}</span>
                          </div>
                        </b-card-body>
                        <vue-apex-charts
                          v-if="chartOptionsComputedData[index]"
                          type="area"
                          height="50"
                          width="100%"
                          :options="chartOptionsComputed"
                          :series="[
                            {
                              name: 'data',
                              data: [76, 35, 78, 11, 42, 64, 86],
                            },
                          ]"
                        />
                      </b-card>
                    </swiper-slide>

                    <div slot="pagination" class="swiper-pagination" />
                  </swiper>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col :cols="displaySound ? '8' : '12'">
            <b-card no-body class="">
              <b-row class="mx-0 mt-1">
                <b-col class="d-flex align-items-center">
                  <h4 class="my-0 pl-1">Kayıtlar</h4>
                </b-col>
                <b-col cols="8">
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="searchQuery"
                      placeholder="Konuşma, Müşteri Adı, Telefon vs..."
                    />
                  </b-input-group>
                </b-col>
              </b-row>
              <b-card-body>
                <div style="display: grid; overflow-x: auto">
                  <b-table
                    :filter="searchQuery"
                    style="min-height: 28.85rem"
                    small
                    @row-dblclicked="load_sound"
                    ref="refInvoiceListTable"
                    :items="records"
                    responsive
                    hover
                    :fields="tableColumns"
                    primary-key="id"
                    show-empty
                    empty-text="No matching records found"
                    class="position-relative mt-"
                  >
                    <template #cell(StationName)="data">
                      {{ data.value.split("_")[0] }}
                    </template>
                    <template #cell(StartDate)="data">
                      {{ data.value.replace("T", " ").slice(0, 19) }}
                    </template>
                    <template #cell(PhoneNumber)="data">
                      {{ data.value.slice(-10) }}
                    </template>
                    <template #cell(QueueName)="data">
                      <b-badge variant="light-primary">
                        {{
                          queues.find((e) => e.internal_name == data.value)
                            ? queues.find((e) => e.internal_name == data.value)
                                .display_name
                            : data.value
                        }}
                      </b-badge>
                    </template>

                    <template #cell(Inbound)="data">
                      <div class="d-flex align-items-center">
                        <b-icon
                          icon="telephone-inbound"
                          v-if="data.value == true"
                        ></b-icon>
                        <b-icon icon="telephone-outbound" v-else></b-icon>

                        <span class="ml-50">{{
                          data.value ? "inbound" : "outbound"
                        }}</span>
                      </div>
                    </template>
                    <template #cell(actions)="">
                      <b-dropdown
                        boundary="window"
                        dropleft
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                      >
                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>
                        <b-dropdown-item>
                          <feather-icon icon="EditIcon" />
                          <span class="align-middle ml-50">E-Posta</span>
                        </b-dropdown-item>

                        <b-dropdown-item>
                          <feather-icon icon="TrashIcon" />
                          <span class="align-middle ml-50">CTI</span>
                        </b-dropdown-item>
                        <b-dropdown-item>
                          <feather-icon icon="TrashIcon" />
                          <span class="align-middle ml-50"
                            >Duygu İstatistikleri</span
                          >
                        </b-dropdown-item>
                        <b-dropdown-item>
                          <feather-icon icon="TrashIcon" />
                          <span class="align-middle ml-50"
                            >Medya İstatistikleri</span
                          >
                        </b-dropdown-item>
                        <b-dropdown-item>
                          <feather-icon icon="TrashIcon" />
                          <span class="align-middle ml-50"
                            >Sinyalleşme Logları</span
                          >
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                  </b-table>
                </div>
              </b-card-body>
              <b-card-footer class="py-75 border-0">
                <div
                  class="d-flex justify-content-between d-flex align-items-center"
                >
                  <div class="d-flex align-items-center mb-0">
                    <!-- <span class="text-nowrap"> Showing 1 to </span> -->
                    <b-form-select
                      size="sm"
                      v-model="perPage"
                      :options="['10', '25', '50']"
                      class="mr-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap text-muted font-small-3">
                      {{
                        lang("t_total") +
                        " " +
                        totalRows +
                        " " +
                        lang("t_row").toLowerCase()
                      }}
                    </span>
                  </div>

                  <b-pagination
                    @input="fetchRecords()"
                    size="sm"
                    class="mb-0"
                    v-model="currentPage"
                    :per-page="perPage"
                    align="right"
                    :total-rows="totalRows"
                    first-number
                    last-number
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="16" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="16" />
                    </template>
                  </b-pagination>
                </div>
              </b-card-footer>
            </b-card>
          </b-col>
          <b-col v-if="displaySound" cols="4">
            <b-card
              style="height: 62vh; overflow-y: auto"
              no-body
              class="mb-0 border-bottom"
            >
              <div>
                <b-row v-if="selected_record" class="border-bottom mx-0">
                  <b-col>
                    <b-row align-h="between" align-v="center">
                      <b-col>
                        <b-avatar
                          size="38"
                          variant="light-secondary"
                          class="my-1"
                        >
                          {{ selected_record.StationName[0].toUpperCase() }}
                        </b-avatar>
                        <span class="ml-1 font-weight-bold">{{
                          selected_record.StationName.split("_")[0]
                        }}</span>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>

              <div>
                <!-- <div style="height: 70vh; overflow-y: auto"> -->
                <div ref="msg_div" class="messages mt-1">
                  <div v-for="(message, i) in messages" :key="i">
                    <b-row class="px-1">
                      <b-col>
                        <span
                          :class="[
                            message.type == 'customer'
                              ? 'msg_from bg-light-secondary'
                              : 'msg_to bg-light-primary',
                            checkKeywordCategory(message.Text) == true
                              ? 'border-warning'
                              : '',
                          ]"
                        >
                          <span
                            v-for="(word, wordIndex) in message.Words"
                            :key="wordIndex"
                            :class="{ highlight: isWordPlaying(word) }"
                          >
                            {{ word.Name }}
                          </span>

                          <small class="time">
                            {{ message.Start }}
                          </small>
                        </span>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>

        <b-row
          :class="displaySound ? 'd-block' : 'd-none'"
          style="
            position: fixed;
            bottom: 0;
            z-index: 9999;
            left: 0;
            width: 100vw;
            margin-left: 0;
            margin-right: 0;
          "
        >
          <b-col class="px-0">
            <b-card class="mb-0">
              <b-card-text>
                <b-row class="mx-0">
                  <b-col
                    class="d-flex align-items-center justify-content-center px-0"
                  >
                    <b-dropdown
                      v-if="wavesurfer"
                      class="mr-50 ml-2"
                      :text="wavesurfer.getPlaybackRate() + 'x'"
                      variant="flat-secondary"
                    >
                      <b-dropdown-item @click="setSoundSpeed(0.25)">
                        0.25x
                      </b-dropdown-item>
                      <b-dropdown-item @click="setSoundSpeed(0.5)">
                        0.5x
                      </b-dropdown-item>
                      <b-dropdown-item @click="setSoundSpeed(0.75)">
                        0.75x
                      </b-dropdown-item>
                      <b-dropdown-item @click="setSoundSpeed(1)">
                        1x
                      </b-dropdown-item>
                      <b-dropdown-item @click="setSoundSpeed(1.25)">
                        1.25x
                      </b-dropdown-item>
                      <b-dropdown-item @click="setSoundSpeed(1.5)">
                        1.50x
                      </b-dropdown-item>
                      <b-dropdown-item @click="setSoundSpeed(1.75)">
                        1.75x
                      </b-dropdown-item>
                      <b-dropdown-item @click="setSoundSpeed(2)">
                        2x
                      </b-dropdown-item>
                    </b-dropdown>
                    <b-button
                      variant="flat-secondary"
                      class="btn-icon rounded-circle"
                    >
                      <feather-icon size="20" icon="SkipBackIcon" />
                    </b-button>
                    <b-button
                      variant="outline-secondary"
                      class="btn-icon rounded-circle mx-50"
                      @click="
                        () => {
                          wavesurfer.playPause();
                        }
                      "
                    >
                      <feather-icon
                        v-if="wavesurfer"
                        size="20"
                        :icon="
                          wavesurfer.isPlaying() ? 'PauseIcon' : 'PlayIcon'
                        "
                      />
                    </b-button>
                    <b-button
                      variant="flat-secondary"
                      class="btn-icon rounded-circle"
                    >
                      <feather-icon size="20" icon="SkipForwardIcon" />
                    </b-button>
                    <span style="width: 3rem" class="ml-3">
                      {{ `${formatTime(parseInt(currentTime))}` }}
                    </span>
                    <b-col class="cursor-pointer" :id="`waveform`"></b-col>
                    <span
                      style="width: 3rem"
                      v-if="wavesurfer && wavesurfer.decodedData"
                    >
                      {{
                        `${formatTime(
                          parseInt(wavesurfer.decodedData.duration)
                        )}`
                      }}
                    </span>
                    <b-button
                      @click="closeSound"
                      variant="flat-secondary"
                      class="btn-icon rounded-circle ml-5"
                    >
                      <feather-icon size="20" icon="XIcon" />
                    </b-button>
                  </b-col>
                </b-row>

                <!-- <div id="hover"></div> -->
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab disabled title="Trend Analizi"></b-tab>
      <b-tab disabled title="Fark Analizi"></b-tab>
    </b-tabs>
    <b-modal
      body-class="my-0 py-0 mx-0 px-0"
      hide-footer
      hide-header
      id="modal-key-word"
      dialog-class="dClass75"
      centered
    >
      <key-word></key-word>
    </b-modal>
  </div>
</template>

<script>
import {
  BPagination,
  BAvatar,
  BMedia,
  BMediaAside,
  BMediaBody,
  BProgress,
  VBTooltip,
  BTable,
  BButtonGroup,
  BFormInput,
  BButton,
  BBadge,
  BContainer,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCardSubTitle,
  BCardHeader,
  BForm,
  BDropdown,
  BDropdownItem,
  BLink,
  BFormRating,
  BInputGroup,
  BInputGroupPrepend,
  BImg,
  BTabs, BTab,
  BAvatarGroup,
  BFormSelect,
  BCardFooter,


} from "bootstrap-vue";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";

import ChartjsComponentLineChart from "./Components/ChartjsComponentLineChart.vue";
import BCardActions from "@core/components/b-card-actions/BCardActionsCallAnalize.vue";
import 'swiper/css/swiper.css'
import { $themeColors } from "@themeConfig";
import WaveSurfer from 'wavesurfer.js'
import KeyWord from './KeyWordCategories/List.vue';
// import WaveSurfer from 'https://unpkg.com/wavesurfer.js@7/dist/wavesurfer.esm.js'
// import RegionsPlugin from 'https://unpkg.com/wavesurfer.js@beta/dist/plugins/regions.js'

// import WaveSurfer from 'https://unpkg.com/wavesurfer.js@beta'
console.log("WaveSurfer", WaveSurfer);
// const canvas = document.createElement('canvas')
// const ctx = canvas.getContext('2d')





const chartColors = {
  column: {
    series1: "#826af9",
    series2: "#d2b0ff",
    bg: "#f8d3ff",
  },
  success: {
    shade_100: "#7eefc7",
    shade_200: "#06774f",
  },
  donut: {
    series1: "#ffe700",
    series2: "#00d4bd",
    series3: "#826bf8",
    series4: "#2b9bf4",
    series5: "#FFA1A1",
  },
  area: {
    series3: "#a4f8cd",
    series2: "#60f2ca",
    series1: "#2bdac7",

    // series1: '#60f2ca',
    // series2: '#ffe700',

    // series3: '#FFA1A1',
  },
};
export default {
  components: {
    BCardFooter,
    BFormSelect,
    BPagination,
    KeyWord,
    flatPickr,
    BTabs, BTab,
    BImg,
    BInputGroupPrepend,
    BAvatarGroup,
    BProgress,
    Swiper,
    SwiperSlide,
    BInputGroup,
    BLink,
    BDropdown,
    BDropdownItem,
    BForm,
    BCardActions,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    VBTooltip,
    BTable,
    BButtonGroup,
    BFormInput,
    VueApexCharts,
    BContainer,
    BRow,
    BCardBody,
    BCol,
    BCard,
    BCardText,
    BBadge,
    vSelect,
    BButton,
    BFormRating,
    ChartjsComponentLineChart,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: false,
      selected_keyword_category: null,
      all_queues: [],
      selected_merged_queues: [],
      merged_queues: [],

      date_filter_types: [
        {
          internal_name: "lastOneHour",
          display_name: globalThis._lang("t_lastOneHour"),
        },
        {
          internal_name: "lastThreeHours",
          display_name: globalThis._lang("t_lastThreeHours"),
        },
        {
          internal_name: "today",
          display_name: globalThis._lang("t_today"),
        },
        {
          internal_name: "yesterday",
          display_name: globalThis._lang("t_yesterday"),
        },
        {
          internal_name: "twoDaysAgo",
          display_name: globalThis._lang("t_twoDaysAgo"),
        },
        {
          internal_name: "threeDaysAgo",
          display_name: globalThis._lang("t_threeDaysAgo"),
        },
        {
          internal_name: "fourDaysAgo",
          display_name: globalThis._lang("t_fourDaysAgo"),
        },
        {
          internal_name: "fiveDaysAgo",
          display_name: globalThis._lang("t_fiveDaysAgo"),
        },
        {
          internal_name: "sixDaysAgo",
          display_name: globalThis._lang("t_sixDaysAgo"),
        },
        {
          internal_name: "lastWeek",
          display_name: globalThis._lang("t_lastWeek"),
        },
        {
          internal_name: "thisMonth",
          display_name: globalThis._lang("t_thisMonth"),
        },
      ],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      currentTime: 0,
      filter_values: {
        startDate: new Date(
          new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
          3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2"),
        endDate: new Date(
          new Date(new Date().setHours(23, 59, 59, 0)).getTime() +
          3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2"),
        selectedQueues: [],
      },
      selected_date_filter_type: "today",
      displaySound: false,
      swiperOptions: {
        autoplay: {
          delay: 10000,
          disableOnInteraction: true,

        },
        slidesPerView: 5,
        spaceBetween: 50,
        pagination: {
          el: '',
          clickable: false,
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
      wavesurfer: null,
      messages: [],
      tableColumns: [
        { key: 'Inbound', label: 'Yön', sortable: true, tdClass: 'text-nowrap' },
        { key: 'QueueName', label: 'Kuyruk', sortable: true, tdClass: 'text-nowrap' },
        { key: 'StationName', label: 'Temsilci', sortable: true, tdClass: 'text-nowrap' },
        { key: 'PbxCallId', label: 'Çağrı ID', sortable: true, tdClass: 'text-nowrap' },
        { key: 'PhoneNumber', label: 'Numara', sortable: true, tdClass: 'text-nowrap' },
        { key: 'StartDate', label: 'Tarih', sortable: true, tdClass: 'text-nowrap' },
        { key: 'SipKeyValue', label: 'Sonuç Kodu', sortable: true, tdClass: 'text-nowrap', },
        { key: 'Duration', label: 'Süre', sortable: true, tdClass: 'text-nowrap', },
        { key: 'AutoEvaluations', label: 'Değerlendirmeler', sortable: true, tdClass: 'text-nowrap', },
        { key: 'actions', label: '', thStyle: { width: '5vh' } },
      ],
      records: [
        {
          "_id": "6628ce88754252a369b45c2d",
          "Station": {
            "No": 1,
            "Extension": "02127090816",
            "IsConferenceRoom": false,
            "Inbound": true,
            "Outbound": true,
            "Speech": true,
            "Stereo": false,
            "Encrypt": false,
            "Encode": true,
            "Qm": true,
            "Name": "ethem.tumba_collecturk",
            "Description": "ethem.tumba_collecturk",
            "SwitchId": "Santral",
            "PbxId": "ethem.tumba_collecturk",
            "RecognitionServerId": "6627a93bd050e39e274de76c",
            "Model": "tr-8",
            "Organization": "collecturk",
            "OrganizationId": "6627a8ebd050e39e274de764"
          },
          "RecorderName": "Kayıt Sunucusu",
          "MachineName": "colvycstt",
          "CallId": "948c9f58-c3da-4c1b-9a2d-0e03bb2f8515",
          "SipCallId": "897b2738-fb13-4921-b7f6-67d8ca2a3574",
          "PbxCallId": "qnnH6fPx4wejyqrXU9JR4",
          "StartDate": "2024-04-24T09:17:56.000Z",
          "StopDate": "2024-04-24T09:19:03.000Z",
          "Duration": 67,
          "PhoneNumber": "05395440619",
          "Inbound": true,
          "HasVideo": false,
          "VideoCodecIn": "",
          "VideoCodecOut": "",
          "HoldCount": 0,
          "HoldDuration": 0,
          "TerminateReason": 0,
          "SipKey": "X-VoyceID",
          "SipKeyValue": "Hat Kesildi",
          "QueueName": "collecturk_queue_dunya_varlik",
          "QueueExtension": "Dünya Varlık",
          "CtiDatas": [
            {
              "PhoneNumber": "05395440619",
              "PbxId": "ethem.tumba_collecturk",
              "CtiCallId": "897b2738-fb13-4921-b7f6-67d8ca2a3574",
              "Queue": "Dünya Varlık",
              "CustomDatas": [
                "CustomerNo=54410040",
                "CustomerName=İSMİHAN SÖNMEZ"
              ],
              "Date": "2024-04-24T09:17:56.000Z",
              "CtiEvent": 0,
              "Direction": 0,
              "TerminateReason": 0,
              "ControlPhoneNumber": false,
              "MatchWithSipCallId": true
            }
          ],
          "Attachments": [],
          "MediaLog": {
            "RtpPacketCountIn": 3531,
            "RtpPacketCountOut": 3458,
            "DublicateRtpPacketCountIn": 0,
            "DublicateRtpPacketCountOut": 0,
            "LostRtpPacketCountIn": 0,
            "LostRtpPacketCountOut": 0,
            "FirstRtpPacketIn": "Apr 24, 2024 12:17:53 PM",
            "FirstRtpPacketOut": "Apr 24, 2024 12:17:54 PM",
            "LastRtpPacketIn": "Apr 24, 2024 12:19:03 PM",
            "LastRtpPacketOut": "Apr 24, 2024 12:19:03 PM",
            "CodecIn": [],
            "CodecOut": [],
            "RtpEncryptionIn": false,
            "RtpEncryptionOut": false,
            "SourcesIn": [
              {
                "IpPortLen": "10.235.13.36-28214 -> 10.22.0.93-17184-180",
                "Count": 3533,
                "FirstDate": "Apr 24, 2024 12:17:53 PM",
                "LastDate": "Apr 24, 2024 12:19:03 PM",
                "a": "10.235.13.36",
                "b": 28214,
                "c": "10.22.0.93",
                "d": 17184
              }
            ],
            "SourcesOut": [
              {
                "IpPortLen": "10.22.0.93-17184 -> 10.235.13.36-28214-180",
                "Count": 3460,
                "FirstDate": "Apr 24, 2024 12:17:54 PM",
                "LastDate": "Apr 24, 2024 12:19:03 PM",
                "a": "10.22.0.93",
                "b": 17184,
                "c": "10.235.13.36",
                "d": 28214
              }
            ]
          },
          "SignalingLogs": [
            {
              "From": "02127090816",
              "To": "05395440619",
              "CallId": "897b2738-fb13-4921-b7f6-67d8ca2a3574",
              "Request": "INVITE",
              "Response": 0,
              "Message": "INVITE sip:05395440619@10.235.13.37:5060 SIP/2.0\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport;branch=z9hG4bKPj2a383113-ceba-4acc-bbfa-2fa2132306ce\r\nFrom: \"VOYCEDialer\" <sip:02127090816@10.22.0.93>;tag=e1a766bf-aca2-4245-bd93-556e51cdaa38\r\nTo: <sip:05395440619@10.235.13.37>\r\nContact: <sip:asterisk@10.22.0.93:5090>\r\nCall-ID: 897b2738-fb13-4921-b7f6-67d8ca2a3574\r\nCSeq: 17745 INVITE\r\nAllow: OPTIONS,  REGISTER,  SUBSCRIBE,  NOTIFY,  PUBLISH,  INVITE,  ACK,  BYE,  CANCEL,  UPDATE,  PRACK,  MESSAGE,  REFER\r\nSupported: 100rel, timer, replaces, norefersub, histinfo\r\nSession-Expires: 1800\r\nMin-SE: 90\r\nX-Extension: VOYCEDialer\r\nP-Early-Media: supported\r\nMax-Forwards: 70\r\nUser-Agent: Asterisk PBX 18.14.0\r\nContent-Type: application/sdp\r\nContent-Length: 304\r\n\r\nv=0\r\no=- 1495491136 1495491136 IN IP4 10.22.0.93\r\ns=Asterisk\r\nc=IN IP4 10.22.0.93\r\nt=0 0\r\nm=audio 17184 RTP/AVP 0 8 18 101\r\na=rtpmap:0 PCMU/8000\r\na=rtpmap:8 PCMA/8000\r\na=rtpmap:18 G729/8000\r\na=fmtp:18 annexb=no\r\na=rtpmap:101 telephone-event/8000\r\na=fmtp:101 0-16\r\na=ptime:20\r\na=maxptime:150\r\na=sendrecv\r\n",
              "MessageDate": "Apr 24, 2024 12:17:47 PM",
              "SrcIpPort": "10.22.0.93:5090",
              "DstIpPort": "10.235.13.37:5060",
              "Transport": "UDP"
            },
            {
              "From": "02127090816",
              "To": "05395440619",
              "CallId": "897b2738-fb13-4921-b7f6-67d8ca2a3574",
              "Request": "",
              "Response": 100,
              "Message": "SIP/2.0 100 Trying\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport=5090;received=10.22.0.93;branch=z9hG4bKPj2a383113-ceba-4acc-bbfa-2fa2132306ce\r\nFrom: \"VOYCEDialer\" <sip:02127090816@10.22.0.93>;tag=e1a766bf-aca2-4245-bd93-556e51cdaa38\r\nTo: <sip:05395440619@10.235.13.37>\r\nCall-ID: 897b2738-fb13-4921-b7f6-67d8ca2a3574\r\nCSeq: 17745 INVITE\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:17:47 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090816",
              "To": "05395440619",
              "CallId": "897b2738-fb13-4921-b7f6-67d8ca2a3574",
              "Request": "",
              "Response": 183,
              "Message": "SIP/2.0 183 Session Progress\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport=5090;received=10.22.0.93;branch=z9hG4bKPj2a383113-ceba-4acc-bbfa-2fa2132306ce\r\nTo: <sip:05395440619@10.235.13.37>;tag=3922939067-952577124\r\nFrom: \"VOYCEDialer\" <sip:02127090816@10.22.0.93>;tag=e1a766bf-aca2-4245-bd93-556e51cdaa38\r\nCall-ID: 897b2738-fb13-4921-b7f6-67d8ca2a3574\r\nCSeq: 17745 INVITE\r\nAllow: PUBLISH, MESSAGE, UPDATE, PRACK, SUBSCRIBE, REFER, INFO, NOTIFY, REGISTER, OPTIONS, BYE, INVITE, ACK, CANCEL\r\nContact: <sip:05395440619@10.235.13.37:5060>\r\nContent-Type: application/sdp\r\nContent-Length: 182\r\n\r\nv=0\r\no=isnet-sbc-kule2 258316196 258316197 IN IP4 10.235.13.37\r\ns=sip call\r\nc=IN IP4 10.235.13.36\r\nt=0 0\r\nm=audio 28214 RTP/AVP 0 101\r\na=rtpmap:101 telephone-event/8000\r\na=ptime:20\r\n",
              "MessageDate": "Apr 24, 2024 12:17:48 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090816",
              "To": "05395440619",
              "CallId": "897b2738-fb13-4921-b7f6-67d8ca2a3574",
              "Request": "",
              "Response": 200,
              "Message": "SIP/2.0 200 OK\r\nSession-Expires: 1800;refresher=uas\r\nRequire: timer\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport=5090;received=10.22.0.93;branch=z9hG4bKPj2a383113-ceba-4acc-bbfa-2fa2132306ce\r\nTo: <sip:05395440619@10.235.13.37>;tag=3922939067-952577124\r\nFrom: \"VOYCEDialer\" <sip:02127090816@10.22.0.93>;tag=e1a766bf-aca2-4245-bd93-556e51cdaa38\r\nCall-ID: 897b2738-fb13-4921-b7f6-67d8ca2a3574\r\nCSeq: 17745 INVITE\r\nAllow: PUBLISH, MESSAGE, UPDATE, PRACK, SUBSCRIBE, REFER, INFO, NOTIFY, REGISTER, OPTIONS, BYE, INVITE, ACK, CANCEL\r\nContact: <sip:05395440619@10.235.13.37:5060>\r\nContent-Type: application/sdp\r\nAccept: application/sdp\r\nAllow-Events: message-summary, refer, dialog, line-seize, presence, call-info, as-feature-event, calling-name, ua-profile\r\nContent-Length: 182\r\n\r\nv=0\r\no=isnet-sbc-kule2 258316196 258316197 IN IP4 10.235.13.37\r\ns=sip call\r\nc=IN IP4 10.235.13.36\r\nt=0 0\r\nm=audio 28214 RTP/AVP 0 101\r\na=rtpmap:101 telephone-event/8000\r\na=ptime:20\r\n",
              "MessageDate": "Apr 24, 2024 12:17:53 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090816",
              "To": "05395440619",
              "CallId": "897b2738-fb13-4921-b7f6-67d8ca2a3574",
              "Request": "ACK",
              "Response": 0,
              "Message": "ACK sip:05395440619@10.235.13.37:5060 SIP/2.0\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport;branch=z9hG4bKPj8d189cfc-4d01-4938-abe4-5c31b9968515\r\nFrom: \"VOYCEDialer\" <sip:02127090816@10.22.0.93>;tag=e1a766bf-aca2-4245-bd93-556e51cdaa38\r\nTo: <sip:05395440619@10.235.13.37>;tag=3922939067-952577124\r\nCall-ID: 897b2738-fb13-4921-b7f6-67d8ca2a3574\r\nCSeq: 17745 ACK\r\nMax-Forwards: 70\r\nUser-Agent: Asterisk PBX 18.14.0\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:17:53 PM",
              "SrcIpPort": "10.22.0.93:5090",
              "DstIpPort": "10.235.13.37:5060",
              "Transport": "UDP"
            },
            {
              "From": "05395440619",
              "To": "02127090816",
              "CallId": "897b2738-fb13-4921-b7f6-67d8ca2a3574",
              "Request": "BYE",
              "Response": 0,
              "Message": "BYE sip:asterisk@10.22.0.93:5090 SIP/2.0\r\nMax-Forwards: 69\r\nTo: \"VOYCEDialer\" <sip:02127090816@10.22.0.93>;tag=e1a766bf-aca2-4245-bd93-556e51cdaa38\r\nFrom: <sip:05395440619@10.235.13.37>;tag=3922939067-952577124\r\nCall-ID: 897b2738-fb13-4921-b7f6-67d8ca2a3574\r\nCSeq: 2 BYE\r\nAllow: PUBLISH, MESSAGE, UPDATE, PRACK, SUBSCRIBE, REFER, INFO, NOTIFY, REGISTER, OPTIONS, BYE, INVITE, ACK, CANCEL\r\nVia: SIP/2.0/UDP 10.235.13.37:5060;branch=z9hG4bK5cc080ae158a75b68267dbe427317ea4\r\nAllow-Events: message-summary, refer, dialog, line-seize, presence, call-info, as-feature-event, calling-name, ua-profile\r\nReason: Q.850;cause=16\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:19:03 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            }
          ],
          "SpeechRules": [
            {
              "Id": "6628c4a31cc8fe45e6d5ea30",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:19:28.168Z",
              "Result": false
            },
            {
              "Id": "6628c47f1cc8fe45e6d5ea2c",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:19:28.168Z",
              "Result": false
            },
            {
              "Id": "65b7670df86bc254da42fd4a",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:19:28.175Z",
              "Result": false
            },
            {
              "Id": "6628c4c91cc8fe45e6d5ea3c",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:19:28.178Z",
              "Result": false
            }
          ]
        },
        {
          "_id": "6628ce05754252a369b45c2b",
          "Station": {
            "No": 8,
            "Extension": "02127090562",
            "IsConferenceRoom": false,
            "Inbound": true,
            "Outbound": true,
            "Speech": true,
            "Stereo": false,
            "Encrypt": false,
            "Encode": true,
            "Qm": true,
            "Name": "ethem.tumba_collecturk",
            "Description": "ethem.tumba_collecturk",
            "SwitchId": "Santral",
            "PbxId": "ethem.tumba_collecturk",
            "RecognitionServerId": "6627a93bd050e39e274de76c",
            "Model": "tr-8",
            "Organization": "collecturk",
            "OrganizationId": "6627a8ebd050e39e274de764"
          },
          "RecorderName": "Kayıt Sunucusu",
          "MachineName": "colvycstt",
          "CallId": "39e29fc5-c7f5-484c-a3e0-4d2329e093a5",
          "SipCallId": "d87ba6d6-c150-4d5c-be08-ddd875e198cf",
          "PbxCallId": "EtHL9ny7dF6zTNNtUKG94",
          "StartDate": "2024-04-24T09:16:32.000Z",
          "StopDate": "2024-04-24T09:16:52.000Z",
          "Duration": 19,
          "PhoneNumber": "02122398510",
          "Inbound": true,
          "HasVideo": false,
          "VideoCodecIn": "",
          "VideoCodecOut": "",
          "HoldCount": 0,
          "HoldDuration": 0,
          "TerminateReason": 0,
          "SipKey": "X-VoyceID",
          "SipKeyValue": "Yanlış Numara",
          "QueueName": "collecturk_queue_dunya_varlik",
          "QueueExtension": "Dünya Varlık",
          "CtiDatas": [
            {
              "PhoneNumber": "02122398510",
              "PbxId": "ethem.tumba_collecturk",
              "CtiCallId": "d87ba6d6-c150-4d5c-be08-ddd875e198cf",
              "Queue": "Dünya Varlık",
              "CustomDatas": [
                "CustomerNo=4558235",
                "CustomerName=OĞUZ YILMAZER"
              ],
              "Date": "2024-04-24T09:16:32.000Z",
              "CtiEvent": 0,
              "Direction": 0,
              "TerminateReason": 0,
              "ControlPhoneNumber": false,
              "MatchWithSipCallId": true
            }
          ],
          "Attachments": [],
          "MediaLog": {
            "RtpPacketCountIn": 1091,
            "RtpPacketCountOut": 1013,
            "DublicateRtpPacketCountIn": 0,
            "DublicateRtpPacketCountOut": 0,
            "LostRtpPacketCountIn": 0,
            "LostRtpPacketCountOut": 0,
            "FirstRtpPacketIn": "Apr 24, 2024 12:16:30 PM",
            "FirstRtpPacketOut": "Apr 24, 2024 12:16:31 PM",
            "LastRtpPacketIn": "Apr 24, 2024 12:16:52 PM",
            "LastRtpPacketOut": "Apr 24, 2024 12:16:52 PM",
            "CodecIn": [],
            "CodecOut": [],
            "RtpEncryptionIn": false,
            "RtpEncryptionOut": false,
            "SourcesIn": [
              {
                "IpPortLen": "10.235.13.36-27372 -> 10.22.0.93-16290-180",
                "Count": 1094,
                "FirstDate": "Apr 24, 2024 12:16:30 PM",
                "LastDate": "Apr 24, 2024 12:16:52 PM",
                "a": "10.235.13.36",
                "b": 27372,
                "c": "10.22.0.93",
                "d": 16290
              }
            ],
            "SourcesOut": [
              {
                "IpPortLen": "10.22.0.93-16290 -> 10.235.13.36-27372-180",
                "Count": 1018,
                "FirstDate": "Apr 24, 2024 12:16:31 PM",
                "LastDate": "Apr 24, 2024 12:16:52 PM",
                "a": "10.22.0.93",
                "b": 16290,
                "c": "10.235.13.36",
                "d": 27372
              }
            ]
          },
          "SignalingLogs": [
            {
              "From": "02127090562",
              "To": "02122398510",
              "CallId": "d87ba6d6-c150-4d5c-be08-ddd875e198cf",
              "Request": "INVITE",
              "Response": 0,
              "Message": "INVITE sip:02122398510@10.235.13.37:5060 SIP/2.0\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport;branch=z9hG4bKPj522db4b8-75c8-4c5f-9013-c68b6ce98214\r\nFrom: \"VOYCEDialer\" <sip:02127090562@10.22.0.93>;tag=e46e1c41-c938-4944-8a9d-b63e59d80944\r\nTo: <sip:02122398510@10.235.13.37>\r\nContact: <sip:asterisk@10.22.0.93:5090>\r\nCall-ID: d87ba6d6-c150-4d5c-be08-ddd875e198cf\r\nCSeq: 21841 INVITE\r\nAllow: OPTIONS,  REGISTER,  SUBSCRIBE,  NOTIFY,  PUBLISH,  INVITE,  ACK,  BYE,  CANCEL,  UPDATE,  PRACK,  MESSAGE,  REFER\r\nSupported: 100rel, timer, replaces, norefersub, histinfo\r\nSession-Expires: 1800\r\nMin-SE: 90\r\nX-Extension: VOYCEDialer\r\nP-Early-Media: supported\r\nMax-Forwards: 70\r\nUser-Agent: Asterisk PBX 18.14.0\r\nContent-Type: application/sdp\r\nContent-Length: 304\r\n\r\nv=0\r\no=- 1952445047 1952445047 IN IP4 10.22.0.93\r\ns=Asterisk\r\nc=IN IP4 10.22.0.93\r\nt=0 0\r\nm=audio 16290 RTP/AVP 0 8 18 101\r\na=rtpmap:0 PCMU/8000\r\na=rtpmap:8 PCMA/8000\r\na=rtpmap:18 G729/8000\r\na=fmtp:18 annexb=no\r\na=rtpmap:101 telephone-event/8000\r\na=fmtp:101 0-16\r\na=ptime:20\r\na=maxptime:150\r\na=sendrecv\r\n",
              "MessageDate": "Apr 24, 2024 12:16:17 PM",
              "SrcIpPort": "10.22.0.93:5090",
              "DstIpPort": "10.235.13.37:5060",
              "Transport": "UDP"
            },
            {
              "From": "02127090562",
              "To": "02122398510",
              "CallId": "d87ba6d6-c150-4d5c-be08-ddd875e198cf",
              "Request": "",
              "Response": 100,
              "Message": "SIP/2.0 100 Trying\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport=5090;received=10.22.0.93;branch=z9hG4bKPj522db4b8-75c8-4c5f-9013-c68b6ce98214\r\nFrom: \"VOYCEDialer\" <sip:02127090562@10.22.0.93>;tag=e46e1c41-c938-4944-8a9d-b63e59d80944\r\nTo: <sip:02122398510@10.235.13.37>\r\nCall-ID: d87ba6d6-c150-4d5c-be08-ddd875e198cf\r\nCSeq: 21841 INVITE\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:16:17 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090562",
              "To": "02122398510",
              "CallId": "d87ba6d6-c150-4d5c-be08-ddd875e198cf",
              "Request": "",
              "Response": 180,
              "Message": "SIP/2.0 180 Ringing\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport=5090;received=10.22.0.93;branch=z9hG4bKPj522db4b8-75c8-4c5f-9013-c68b6ce98214\r\nTo: <sip:02122398510@10.235.13.37>;tag=3922938977-653813167\r\nFrom: \"VOYCEDialer\" <sip:02127090562@10.22.0.93>;tag=e46e1c41-c938-4944-8a9d-b63e59d80944\r\nCall-ID: d87ba6d6-c150-4d5c-be08-ddd875e198cf\r\nCSeq: 21841 INVITE\r\nAllow: PUBLISH, MESSAGE, UPDATE, PRACK, SUBSCRIBE, REFER, INFO, NOTIFY, REGISTER, OPTIONS, BYE, INVITE, ACK, CANCEL\r\nContact: <sip:02122398510@10.235.13.37:5060>\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:16:17 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090562",
              "To": "02122398510",
              "CallId": "d87ba6d6-c150-4d5c-be08-ddd875e198cf",
              "Request": "",
              "Response": 200,
              "Message": "SIP/2.0 200 OK\r\nSession-Expires: 1800;refresher=uas\r\nRequire: timer\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport=5090;received=10.22.0.93;branch=z9hG4bKPj522db4b8-75c8-4c5f-9013-c68b6ce98214\r\nTo: <sip:02122398510@10.235.13.37>;tag=3922938977-653813167\r\nFrom: \"VOYCEDialer\" <sip:02127090562@10.22.0.93>;tag=e46e1c41-c938-4944-8a9d-b63e59d80944\r\nCall-ID: d87ba6d6-c150-4d5c-be08-ddd875e198cf\r\nCSeq: 21841 INVITE\r\nAllow: PUBLISH, MESSAGE, UPDATE, PRACK, SUBSCRIBE, REFER, INFO, NOTIFY, REGISTER, OPTIONS, BYE, INVITE, ACK, CANCEL\r\nContact: <sip:02122398510@10.235.13.37:5060>\r\nContent-Type: application/sdp\r\nAccept: application/sdp\r\nAllow-Events: message-summary, refer, dialog, line-seize, presence, call-info, as-feature-event, calling-name, ua-profile\r\nContent-Length: 143\r\n\r\nv=0\r\no=isnet-sbc-kule2 258226204 258226205 IN IP4 10.235.13.37\r\ns=sip call\r\nc=IN IP4 10.235.13.36\r\nt=0 0\r\nm=audio 27372 RTP/AVP 0\r\na=ptime:20\r\n",
              "MessageDate": "Apr 24, 2024 12:16:30 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090562",
              "To": "02122398510",
              "CallId": "d87ba6d6-c150-4d5c-be08-ddd875e198cf",
              "Request": "ACK",
              "Response": 0,
              "Message": "ACK sip:02122398510@10.235.13.37:5060 SIP/2.0\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport;branch=z9hG4bKPj5da48b0c-5e73-42f8-81d2-184b86b4d294\r\nFrom: \"VOYCEDialer\" <sip:02127090562@10.22.0.93>;tag=e46e1c41-c938-4944-8a9d-b63e59d80944\r\nTo: <sip:02122398510@10.235.13.37>;tag=3922938977-653813167\r\nCall-ID: d87ba6d6-c150-4d5c-be08-ddd875e198cf\r\nCSeq: 21841 ACK\r\nMax-Forwards: 70\r\nUser-Agent: Asterisk PBX 18.14.0\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:16:30 PM",
              "SrcIpPort": "10.22.0.93:5090",
              "DstIpPort": "10.235.13.37:5060",
              "Transport": "UDP"
            },
            {
              "From": "02122398510",
              "To": "02127090562",
              "CallId": "d87ba6d6-c150-4d5c-be08-ddd875e198cf",
              "Request": "BYE",
              "Response": 0,
              "Message": "BYE sip:asterisk@10.22.0.93:5090 SIP/2.0\r\nMax-Forwards: 69\r\nTo: \"VOYCEDialer\" <sip:02127090562@10.22.0.93>;tag=e46e1c41-c938-4944-8a9d-b63e59d80944\r\nFrom: <sip:02122398510@10.235.13.37>;tag=3922938977-653813167\r\nCall-ID: d87ba6d6-c150-4d5c-be08-ddd875e198cf\r\nCSeq: 2 BYE\r\nAllow: PUBLISH, MESSAGE, UPDATE, PRACK, SUBSCRIBE, REFER, INFO, NOTIFY, REGISTER, OPTIONS, BYE, INVITE, ACK, CANCEL\r\nVia: SIP/2.0/UDP 10.235.13.37:5060;branch=z9hG4bK894c0533d0b7f117ff6c07b6745eb8b8\r\nAllow-Events: message-summary, refer, dialog, line-seize, presence, call-info, as-feature-event, calling-name, ua-profile\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:16:52 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            }
          ],
          "SpeechRules": [
            {
              "Id": "6628c4a31cc8fe45e6d5ea30",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:17:18.170Z",
              "Result": false
            },
            {
              "Id": "6628c47f1cc8fe45e6d5ea2c",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:17:18.172Z",
              "Result": false
            },
            {
              "Id": "65b7670df86bc254da42fd4a",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:17:18.180Z",
              "Result": false
            },
            {
              "Id": "6628c4c91cc8fe45e6d5ea3c",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:17:18.183Z",
              "Result": false
            }
          ]
        },
        {
          "_id": "6628cdd7754252a369b45c29",
          "Station": {
            "No": 5,
            "Extension": "02127090532",
            "IsConferenceRoom": false,
            "Inbound": true,
            "Outbound": true,
            "Speech": true,
            "Stereo": false,
            "Encrypt": false,
            "Encode": true,
            "Qm": true,
            "Name": "ethem.tumba_collecturk",
            "Description": "ethem.tumba_collecturk",
            "SwitchId": "Santral",
            "PbxId": "ethem.tumba_collecturk",
            "RecognitionServerId": "6627a93bd050e39e274de76c",
            "Model": "tr-8",
            "Organization": "collecturk",
            "OrganizationId": "6627a8ebd050e39e274de764"
          },
          "RecorderName": "Kayıt Sunucusu",
          "MachineName": "colvycstt",
          "CallId": "7fe01438-36e8-4004-965c-93e27214037f",
          "SipCallId": "18fedf21-d651-46f8-a7ec-40799029cde2",
          "PbxCallId": "X6LBRfztEJULQYHWhagak",
          "StartDate": "2024-04-24T09:16:02.000Z",
          "StopDate": "2024-04-24T09:16:06.000Z",
          "Duration": 4,
          "PhoneNumber": "02128861239",
          "Inbound": true,
          "HasVideo": false,
          "VideoCodecIn": "",
          "VideoCodecOut": "",
          "HoldCount": 0,
          "HoldDuration": 0,
          "TerminateReason": 1,
          "SipKey": "X-VoyceID",
          "SipKeyValue": "Telesekreter / Fax",
          "QueueName": "collecturk_queue_dunya_varlik",
          "QueueExtension": "Dünya Varlık",
          "CtiDatas": [
            {
              "PhoneNumber": "02128861239",
              "PbxId": "ethem.tumba_collecturk",
              "CtiCallId": "18fedf21-d651-46f8-a7ec-40799029cde2",
              "Queue": "Dünya Varlık",
              "CustomDatas": [
                "CustomerNo=14391141",
                "CustomerName=YEŞİM ÖZKAN"
              ],
              "Date": "2024-04-24T09:16:02.000Z",
              "CtiEvent": 0,
              "Direction": 0,
              "TerminateReason": 0,
              "ControlPhoneNumber": false,
              "MatchWithSipCallId": true
            }
          ],
          "Attachments": [],
          "MediaLog": {
            "RtpPacketCountIn": 203,
            "RtpPacketCountOut": 203,
            "DublicateRtpPacketCountIn": 0,
            "DublicateRtpPacketCountOut": 0,
            "LostRtpPacketCountIn": 0,
            "LostRtpPacketCountOut": 0,
            "FirstRtpPacketIn": "Apr 24, 2024 12:16:02 PM",
            "FirstRtpPacketOut": "Apr 24, 2024 12:16:02 PM",
            "LastRtpPacketIn": "Apr 24, 2024 12:16:06 PM",
            "LastRtpPacketOut": "Apr 24, 2024 12:16:06 PM",
            "CodecIn": [],
            "CodecOut": [],
            "RtpEncryptionIn": false,
            "RtpEncryptionOut": false,
            "SourcesIn": [
              {
                "IpPortLen": "10.235.13.36-26630 -> 10.22.0.93-13460-180",
                "Count": 204,
                "FirstDate": "Apr 24, 2024 12:16:02 PM",
                "LastDate": "Apr 24, 2024 12:16:06 PM",
                "a": "10.235.13.36",
                "b": 26630,
                "c": "10.22.0.93",
                "d": 13460
              }
            ],
            "SourcesOut": [
              {
                "IpPortLen": "10.22.0.93-13460 -> 10.235.13.36-26630-180",
                "Count": 203,
                "FirstDate": "Apr 24, 2024 12:16:02 PM",
                "LastDate": "Apr 24, 2024 12:16:06 PM",
                "a": "10.22.0.93",
                "b": 13460,
                "c": "10.235.13.36",
                "d": 26630
              }
            ]
          },
          "SignalingLogs": [
            {
              "From": "02127090532",
              "To": "02128861239",
              "CallId": "18fedf21-d651-46f8-a7ec-40799029cde2",
              "Request": "INVITE",
              "Response": 0,
              "Message": "INVITE sip:02128861239@10.235.13.37:5060 SIP/2.0\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport;branch=z9hG4bKPjd5af635b-e925-4c7d-9acc-a40a0f0dae04\r\nFrom: \"VOYCEDialer\" <sip:02127090532@10.22.0.93>;tag=8bab3f6b-87f8-4877-9db6-9d4f49b38bf8\r\nTo: <sip:02128861239@10.235.13.37>\r\nContact: <sip:asterisk@10.22.0.93:5090>\r\nCall-ID: 18fedf21-d651-46f8-a7ec-40799029cde2\r\nCSeq: 2018 INVITE\r\nAllow: OPTIONS,  REGISTER,  SUBSCRIBE,  NOTIFY,  PUBLISH,  INVITE,  ACK,  BYE,  CANCEL,  UPDATE,  PRACK,  MESSAGE,  REFER\r\nSupported: 100rel, timer, replaces, norefersub, histinfo\r\nSession-Expires: 1800\r\nMin-SE: 90\r\nP-Early-Media: supported\r\nMax-Forwards: 70\r\nUser-Agent: Asterisk PBX 18.14.0\r\nContent-Type: application/sdp\r\nContent-Length: 298\r\n\r\nv=0\r\no=- 5940217 5940217 IN IP4 10.22.0.93\r\ns=Asterisk\r\nc=IN IP4 10.22.0.93\r\nt=0 0\r\nm=audio 13460 RTP/AVP 0 8 18 101\r\na=rtpmap:0 PCMU/8000\r\na=rtpmap:8 PCMA/8000\r\na=rtpmap:18 G729/8000\r\na=fmtp:18 annexb=no\r\na=rtpmap:101 telephone-event/8000\r\na=fmtp:101 0-16\r\na=ptime:20\r\na=maxptime:150\r\na=sendrecv\r\n",
              "MessageDate": "Apr 24, 2024 12:15:57 PM",
              "SrcIpPort": "10.22.0.93:5090",
              "DstIpPort": "10.235.13.37:5060",
              "Transport": "UDP"
            },
            {
              "From": "02127090532",
              "To": "02128861239",
              "CallId": "18fedf21-d651-46f8-a7ec-40799029cde2",
              "Request": "",
              "Response": 100,
              "Message": "SIP/2.0 100 Trying\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport=5090;received=10.22.0.93;branch=z9hG4bKPjd5af635b-e925-4c7d-9acc-a40a0f0dae04\r\nFrom: \"VOYCEDialer\" <sip:02127090532@10.22.0.93>;tag=8bab3f6b-87f8-4877-9db6-9d4f49b38bf8\r\nTo: <sip:02128861239@10.235.13.37>\r\nCall-ID: 18fedf21-d651-46f8-a7ec-40799029cde2\r\nCSeq: 2018 INVITE\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:15:57 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090532",
              "To": "02128861239",
              "CallId": "18fedf21-d651-46f8-a7ec-40799029cde2",
              "Request": "",
              "Response": 180,
              "Message": "SIP/2.0 180 Ringing\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport=5090;received=10.22.0.93;branch=z9hG4bKPjd5af635b-e925-4c7d-9acc-a40a0f0dae04\r\nTo: <sip:02128861239@10.235.13.37>;tag=3922938957-163810323\r\nFrom: \"VOYCEDialer\" <sip:02127090532@10.22.0.93>;tag=8bab3f6b-87f8-4877-9db6-9d4f49b38bf8\r\nCall-ID: 18fedf21-d651-46f8-a7ec-40799029cde2\r\nCSeq: 2018 INVITE\r\nAllow: PUBLISH, MESSAGE, UPDATE, PRACK, SUBSCRIBE, REFER, INFO, NOTIFY, REGISTER, OPTIONS, BYE, INVITE, ACK, CANCEL\r\nContact: <sip:02128861239@10.235.13.37:5060>\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:15:57 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090532",
              "To": "02128861239",
              "CallId": "18fedf21-d651-46f8-a7ec-40799029cde2",
              "Request": "",
              "Response": 200,
              "Message": "SIP/2.0 200 OK\r\nSession-Expires: 1800;refresher=uas\r\nRequire: timer\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport=5090;received=10.22.0.93;branch=z9hG4bKPjd5af635b-e925-4c7d-9acc-a40a0f0dae04\r\nTo: <sip:02128861239@10.235.13.37>;tag=3922938957-163810323\r\nFrom: \"VOYCEDialer\" <sip:02127090532@10.22.0.93>;tag=8bab3f6b-87f8-4877-9db6-9d4f49b38bf8\r\nCall-ID: 18fedf21-d651-46f8-a7ec-40799029cde2\r\nCSeq: 2018 INVITE\r\nAllow: PUBLISH, MESSAGE, UPDATE, PRACK, SUBSCRIBE, REFER, INFO, NOTIFY, REGISTER, OPTIONS, BYE, INVITE, ACK, CANCEL\r\nContact: <sip:02128861239@10.235.13.37:5060>\r\nContent-Type: application/sdp\r\nAccept: application/sdp\r\nAllow-Events: message-summary, refer, dialog, line-seize, presence, call-info, as-feature-event, calling-name, ua-profile\r\nContent-Length: 182\r\n\r\nv=0\r\no=isnet-sbc-kule2 258206291 258206292 IN IP4 10.235.13.37\r\ns=sip call\r\nc=IN IP4 10.235.13.36\r\nt=0 0\r\nm=audio 26630 RTP/AVP 8 101\r\na=rtpmap:101 telephone-event/8000\r\na=ptime:20\r\n",
              "MessageDate": "Apr 24, 2024 12:15:59 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090532",
              "To": "02128861239",
              "CallId": "18fedf21-d651-46f8-a7ec-40799029cde2",
              "Request": "ACK",
              "Response": 0,
              "Message": "ACK sip:02128861239@10.235.13.37:5060 SIP/2.0\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport;branch=z9hG4bKPj35578d12-04a2-405e-a26d-7d1b12f406b0\r\nFrom: \"VOYCEDialer\" <sip:02127090532@10.22.0.93>;tag=8bab3f6b-87f8-4877-9db6-9d4f49b38bf8\r\nTo: <sip:02128861239@10.235.13.37>;tag=3922938957-163810323\r\nCall-ID: 18fedf21-d651-46f8-a7ec-40799029cde2\r\nCSeq: 2018 ACK\r\nMax-Forwards: 70\r\nUser-Agent: Asterisk PBX 18.14.0\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:15:59 PM",
              "SrcIpPort": "10.22.0.93:5090",
              "DstIpPort": "10.235.13.37:5060",
              "Transport": "UDP"
            },
            {
              "From": "02127090532",
              "To": "02128861239",
              "CallId": "18fedf21-d651-46f8-a7ec-40799029cde2",
              "Request": "BYE",
              "Response": 0,
              "Message": "BYE sip:02128861239@10.235.13.37:5060 SIP/2.0\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport;branch=z9hG4bKPj12713049-c468-45df-abf4-5d02ce57bee9\r\nFrom: \"VOYCEDialer\" <sip:02127090532@10.22.0.93>;tag=8bab3f6b-87f8-4877-9db6-9d4f49b38bf8\r\nTo: <sip:02128861239@10.235.13.37>;tag=3922938957-163810323\r\nCall-ID: 18fedf21-d651-46f8-a7ec-40799029cde2\r\nCSeq: 2019 BYE\r\nReason: Q.850;cause=16\r\nMax-Forwards: 70\r\nUser-Agent: Asterisk PBX 18.14.0\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:16:06 PM",
              "SrcIpPort": "10.22.0.93:5090",
              "DstIpPort": "10.235.13.37:5060",
              "Transport": "UDP"
            }
          ],
          "SpeechRules": [
            {
              "Id": "6628c4a31cc8fe45e6d5ea30",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:16:16.495Z",
              "Result": false
            },
            {
              "Id": "6628c47f1cc8fe45e6d5ea2c",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:16:16.495Z",
              "Result": false
            },
            {
              "Id": "65b7670df86bc254da42fd4a",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:16:16.495Z",
              "Result": false
            },
            {
              "Id": "6628c4c91cc8fe45e6d5ea3c",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:16:16.495Z",
              "Result": false
            }
          ]
        },
        {
          "_id": "6628cdb0754252a369b45c28",
          "Station": {
            "No": 6,
            "Extension": "02127090542",
            "IsConferenceRoom": false,
            "Inbound": true,
            "Outbound": true,
            "Speech": true,
            "Stereo": false,
            "Encrypt": false,
            "Encode": true,
            "Qm": true,
            "Name": "ethem.tumba_collecturk",
            "Description": "ethem.tumba_collecturk",
            "SwitchId": "Santral",
            "PbxId": "ethem.tumba_collecturk",
            "RecognitionServerId": "6627a93bd050e39e274de76c",
            "Model": "tr-8",
            "Organization": "collecturk",
            "OrganizationId": "6627a8ebd050e39e274de764"
          },
          "RecorderName": "Kayıt Sunucusu",
          "MachineName": "colvycstt",
          "CallId": "f2c28930-ab15-4362-a4eb-7c677e84dd85",
          "SipCallId": "459a7020-2efc-489c-be8f-7c01850f27c6",
          "PbxCallId": "Fxqgz9MfydtJATYQCC9Qj",
          "StartDate": "2024-04-24T09:15:24.000Z",
          "StopDate": "2024-04-24T09:15:27.000Z",
          "Duration": 3,
          "PhoneNumber": "02126590059",
          "Inbound": true,
          "HasVideo": false,
          "VideoCodecIn": "",
          "VideoCodecOut": "",
          "HoldCount": 0,
          "HoldDuration": 0,
          "TerminateReason": 1,
          "SipKey": "X-VoyceID",
          "SipKeyValue": "Telesekreter / Fax",
          "QueueName": "collecturk_queue_dunya_varlik",
          "QueueExtension": "Dünya Varlık",
          "CtiDatas": [
            {
              "PhoneNumber": "02126590059",
              "PbxId": "ethem.tumba_collecturk",
              "CtiCallId": "459a7020-2efc-489c-be8f-7c01850f27c6",
              "Queue": "Dünya Varlık",
              "CustomDatas": [
                "CustomerNo=10722264",
                "CustomerName=YUNUS KÖKLÜ"
              ],
              "Date": "2024-04-24T09:15:24.000Z",
              "CtiEvent": 0,
              "Direction": 0,
              "TerminateReason": 0,
              "ControlPhoneNumber": false,
              "MatchWithSipCallId": true
            }
          ],
          "Attachments": [],
          "MediaLog": {
            "RtpPacketCountIn": 280,
            "RtpPacketCountOut": 254,
            "DublicateRtpPacketCountIn": 0,
            "DublicateRtpPacketCountOut": 0,
            "LostRtpPacketCountIn": 0,
            "LostRtpPacketCountOut": 0,
            "FirstRtpPacketIn": "Apr 24, 2024 12:15:22 PM",
            "FirstRtpPacketOut": "Apr 24, 2024 12:15:22 PM",
            "LastRtpPacketIn": "Apr 24, 2024 12:15:27 PM",
            "LastRtpPacketOut": "Apr 24, 2024 12:15:27 PM",
            "CodecIn": [],
            "CodecOut": [],
            "RtpEncryptionIn": false,
            "RtpEncryptionOut": false,
            "SourcesIn": [
              {
                "IpPortLen": "10.235.13.36-25838 -> 10.22.0.93-10034-40",
                "Count": 283,
                "FirstDate": "Apr 24, 2024 12:15:22 PM",
                "LastDate": "Apr 24, 2024 12:15:27 PM",
                "a": "10.235.13.36",
                "b": 25838,
                "c": "10.22.0.93",
                "d": 10034
              }
            ],
            "SourcesOut": [
              {
                "IpPortLen": "10.22.0.93-10034 -> 10.235.13.36-25838-40",
                "Count": 256,
                "FirstDate": "Apr 24, 2024 12:15:22 PM",
                "LastDate": "Apr 24, 2024 12:15:27 PM",
                "a": "10.22.0.93",
                "b": 10034,
                "c": "10.235.13.36",
                "d": 25838
              }
            ]
          },
          "SignalingLogs": [
            {
              "From": "02127090542",
              "To": "02126590059",
              "CallId": "459a7020-2efc-489c-be8f-7c01850f27c6",
              "Request": "INVITE",
              "Response": 0,
              "Message": "INVITE sip:02126590059@10.235.13.37:5060 SIP/2.0\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport;branch=z9hG4bKPja2d2dda9-3a0e-4ff6-833d-d4a4e3ec1767\r\nFrom: \"VOYCEDialer\" <sip:02127090542@10.22.0.93>;tag=4a10a787-4e08-42f9-98e0-7c946c56bcac\r\nTo: <sip:02126590059@10.235.13.37>\r\nContact: <sip:asterisk@10.22.0.93:5090>\r\nCall-ID: 459a7020-2efc-489c-be8f-7c01850f27c6\r\nCSeq: 2865 INVITE\r\nAllow: OPTIONS,  REGISTER,  SUBSCRIBE,  NOTIFY,  PUBLISH,  INVITE,  ACK,  BYE,  CANCEL,  UPDATE,  PRACK,  MESSAGE,  REFER\r\nSupported: 100rel, timer, replaces, norefersub, histinfo\r\nSession-Expires: 1800\r\nMin-SE: 90\r\nX-Extension: VOYCEDialer\r\nP-Early-Media: supported\r\nMax-Forwards: 70\r\nUser-Agent: Asterisk PBX 18.14.0\r\nContent-Type: application/sdp\r\nContent-Length: 302\r\n\r\nv=0\r\no=- 187151705 187151705 IN IP4 10.22.0.93\r\ns=Asterisk\r\nc=IN IP4 10.22.0.93\r\nt=0 0\r\nm=audio 10034 RTP/AVP 0 8 18 101\r\na=rtpmap:0 PCMU/8000\r\na=rtpmap:8 PCMA/8000\r\na=rtpmap:18 G729/8000\r\na=fmtp:18 annexb=no\r\na=rtpmap:101 telephone-event/8000\r\na=fmtp:101 0-16\r\na=ptime:20\r\na=maxptime:150\r\na=sendrecv\r\n",
              "MessageDate": "Apr 24, 2024 12:15:19 PM",
              "SrcIpPort": "10.22.0.93:5090",
              "DstIpPort": "10.235.13.37:5060",
              "Transport": "UDP"
            },
            {
              "From": "02127090542",
              "To": "02126590059",
              "CallId": "459a7020-2efc-489c-be8f-7c01850f27c6",
              "Request": "",
              "Response": 100,
              "Message": "SIP/2.0 100 Trying\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport=5090;received=10.22.0.93;branch=z9hG4bKPja2d2dda9-3a0e-4ff6-833d-d4a4e3ec1767\r\nFrom: \"VOYCEDialer\" <sip:02127090542@10.22.0.93>;tag=4a10a787-4e08-42f9-98e0-7c946c56bcac\r\nTo: <sip:02126590059@10.235.13.37>\r\nCall-ID: 459a7020-2efc-489c-be8f-7c01850f27c6\r\nCSeq: 2865 INVITE\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:15:19 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090542",
              "To": "02126590059",
              "CallId": "459a7020-2efc-489c-be8f-7c01850f27c6",
              "Request": "",
              "Response": 200,
              "Message": "SIP/2.0 200 OK\r\nSession-Expires: 1800;refresher=uas\r\nRequire: timer\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport=5090;received=10.22.0.93;branch=z9hG4bKPja2d2dda9-3a0e-4ff6-833d-d4a4e3ec1767\r\nTo: <sip:02126590059@10.235.13.37>;tag=3922938920-850822202\r\nFrom: \"VOYCEDialer\" <sip:02127090542@10.22.0.93>;tag=4a10a787-4e08-42f9-98e0-7c946c56bcac\r\nCall-ID: 459a7020-2efc-489c-be8f-7c01850f27c6\r\nCSeq: 2865 INVITE\r\nAllow: PUBLISH, MESSAGE, UPDATE, PRACK, SUBSCRIBE, REFER, INFO, NOTIFY, REGISTER, OPTIONS, BYE, INVITE, ACK, CANCEL\r\nContact: <sip:02126590059@10.235.13.37:5060>\r\nContent-Type: application/sdp\r\nAccept: application/sdp\r\nAllow-Events: message-summary, refer, dialog, line-seize, presence, call-info, as-feature-event, calling-name, ua-profile\r\nContent-Length: 208\r\n\r\nv=0\r\no=isnet-sbc-kule2 258168382 258168383 IN IP4 10.235.13.37\r\ns=sip call\r\nc=IN IP4 10.235.13.36\r\nt=0 0\r\nm=audio 25838 RTP/AVP 18 0 8 101\r\na=fmtp:18 annexb=no\r\na=rtpmap:101 telephone-event/8000\r\na=ptime:20\r\n",
              "MessageDate": "Apr 24, 2024 12:15:21 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090542",
              "To": "02126590059",
              "CallId": "459a7020-2efc-489c-be8f-7c01850f27c6",
              "Request": "ACK",
              "Response": 0,
              "Message": "ACK sip:02126590059@10.235.13.37:5060 SIP/2.0\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport;branch=z9hG4bKPj2253a640-ad42-4b9c-91d4-63269ab96b7c\r\nFrom: \"VOYCEDialer\" <sip:02127090542@10.22.0.93>;tag=4a10a787-4e08-42f9-98e0-7c946c56bcac\r\nTo: <sip:02126590059@10.235.13.37>;tag=3922938920-850822202\r\nCall-ID: 459a7020-2efc-489c-be8f-7c01850f27c6\r\nCSeq: 2865 ACK\r\nMax-Forwards: 70\r\nUser-Agent: Asterisk PBX 18.14.0\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:15:21 PM",
              "SrcIpPort": "10.22.0.93:5090",
              "DstIpPort": "10.235.13.37:5060",
              "Transport": "UDP"
            },
            {
              "From": "02127090542",
              "To": "02126590059",
              "CallId": "459a7020-2efc-489c-be8f-7c01850f27c6",
              "Request": "BYE",
              "Response": 0,
              "Message": "BYE sip:02126590059@10.235.13.37:5060 SIP/2.0\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport;branch=z9hG4bKPj07851d74-5d7f-4c95-8204-1b017237dc1a\r\nFrom: \"VOYCEDialer\" <sip:02127090542@10.22.0.93>;tag=4a10a787-4e08-42f9-98e0-7c946c56bcac\r\nTo: <sip:02126590059@10.235.13.37>;tag=3922938920-850822202\r\nCall-ID: 459a7020-2efc-489c-be8f-7c01850f27c6\r\nCSeq: 2866 BYE\r\nReason: Q.850;cause=16\r\nMax-Forwards: 70\r\nUser-Agent: Asterisk PBX 18.14.0\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:15:27 PM",
              "SrcIpPort": "10.22.0.93:5090",
              "DstIpPort": "10.235.13.37:5060",
              "Transport": "UDP"
            }
          ],
          "SpeechRules": [
            {
              "Id": "6628c4a31cc8fe45e6d5ea30",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:16:17.198Z",
              "Result": false
            },
            {
              "Id": "6628c47f1cc8fe45e6d5ea2c",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:16:17.198Z",
              "Result": false
            },
            {
              "Id": "65b7670df86bc254da42fd4a",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:16:17.199Z",
              "Result": false
            },
            {
              "Id": "6628c4c91cc8fe45e6d5ea3c",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:16:17.199Z",
              "Result": false
            }
          ]
        },
        {
          "_id": "6628cd7c754252a369b45c27",
          "Station": {
            "No": 4,
            "Extension": "02127090522",
            "IsConferenceRoom": false,
            "Inbound": true,
            "Outbound": true,
            "Speech": true,
            "Stereo": false,
            "Encrypt": false,
            "Encode": true,
            "Qm": true,
            "Name": "ethem.tumba_collecturk",
            "Description": "ethem.tumba_collecturk",
            "SwitchId": "Santral",
            "PbxId": "ethem.tumba_collecturk",
            "RecognitionServerId": "6627a93bd050e39e274de76c",
            "Model": "tr-8",
            "Organization": "collecturk",
            "OrganizationId": "6627a8ebd050e39e274de764"
          },
          "RecorderName": "Kayıt Sunucusu",
          "MachineName": "colvycstt",
          "CallId": "cbe3267b-7c1e-42ea-b026-70170bc2a0b1",
          "SipCallId": "cd76975f-9416-4120-88fd-80b779af27bf",
          "PbxCallId": "ebtUeMgYLbHgWXBgqtWrw",
          "StartDate": "2024-04-24T09:14:30.000Z",
          "StopDate": "2024-04-24T09:14:35.000Z",
          "Duration": 4,
          "PhoneNumber": "02242705000",
          "Inbound": true,
          "HasVideo": false,
          "VideoCodecIn": "",
          "VideoCodecOut": "",
          "HoldCount": 0,
          "HoldDuration": 0,
          "TerminateReason": 1,
          "SipKey": "X-VoyceID",
          "SipKeyValue": "Telesekreter / Fax",
          "QueueName": "collecturk_queue_dunya_varlik",
          "QueueExtension": "Dünya Varlık",
          "CtiDatas": [
            {
              "PhoneNumber": "02242705000",
              "PbxId": "ethem.tumba_collecturk",
              "CtiCallId": "cd76975f-9416-4120-88fd-80b779af27bf",
              "Queue": "Dünya Varlık",
              "CustomDatas": [
                "CustomerNo=77848811",
                "CustomerName=ATİLA ARAS"
              ],
              "Date": "2024-04-24T09:14:30.000Z",
              "CtiEvent": 0,
              "Direction": 0,
              "TerminateReason": 0,
              "ControlPhoneNumber": false,
              "MatchWithSipCallId": true
            }
          ],
          "Attachments": [],
          "MediaLog": {
            "RtpPacketCountIn": 229,
            "RtpPacketCountOut": 229,
            "DublicateRtpPacketCountIn": 0,
            "DublicateRtpPacketCountOut": 0,
            "LostRtpPacketCountIn": 0,
            "LostRtpPacketCountOut": 0,
            "FirstRtpPacketIn": "Apr 24, 2024 12:14:30 PM",
            "FirstRtpPacketOut": "Apr 24, 2024 12:14:30 PM",
            "LastRtpPacketIn": "Apr 24, 2024 12:14:35 PM",
            "LastRtpPacketOut": "Apr 24, 2024 12:14:35 PM",
            "CodecIn": [],
            "CodecOut": [],
            "RtpEncryptionIn": false,
            "RtpEncryptionOut": false,
            "SourcesIn": [
              {
                "IpPortLen": "10.235.13.36-25466 -> 10.22.0.93-15330-180",
                "Count": 230,
                "FirstDate": "Apr 24, 2024 12:14:30 PM",
                "LastDate": "Apr 24, 2024 12:14:35 PM",
                "a": "10.235.13.36",
                "b": 25466,
                "c": "10.22.0.93",
                "d": 15330
              }
            ],
            "SourcesOut": [
              {
                "IpPortLen": "10.22.0.93-15330 -> 10.235.13.36-25466-180",
                "Count": 229,
                "FirstDate": "Apr 24, 2024 12:14:30 PM",
                "LastDate": "Apr 24, 2024 12:14:35 PM",
                "a": "10.22.0.93",
                "b": 15330,
                "c": "10.235.13.36",
                "d": 25466
              }
            ]
          },
          "SignalingLogs": [
            {
              "From": "02127090522",
              "To": "02242705000",
              "CallId": "cd76975f-9416-4120-88fd-80b779af27bf",
              "Request": "INVITE",
              "Response": 0,
              "Message": "INVITE sip:02242705000@10.235.13.37:5060 SIP/2.0\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport;branch=z9hG4bKPj1a458857-7939-417d-b80b-cadb2788efad\r\nFrom: \"VOYCEDialer\" <sip:02127090522@10.22.0.93>;tag=33193002-9ea2-4b48-8312-2c6a12af0f60\r\nTo: <sip:02242705000@10.235.13.37>\r\nContact: <sip:asterisk@10.22.0.93:5090>\r\nCall-ID: cd76975f-9416-4120-88fd-80b779af27bf\r\nCSeq: 2194 INVITE\r\nAllow: OPTIONS,  REGISTER,  SUBSCRIBE,  NOTIFY,  PUBLISH,  INVITE,  ACK,  BYE,  CANCEL,  UPDATE,  PRACK,  MESSAGE,  REFER\r\nSupported: 100rel, timer, replaces, norefersub, histinfo\r\nSession-Expires: 1800\r\nMin-SE: 90\r\nP-Early-Media: supported\r\nMax-Forwards: 70\r\nUser-Agent: Asterisk PBX 18.14.0\r\nContent-Type: application/sdp\r\nContent-Length: 302\r\n\r\nv=0\r\no=- 820347170 820347170 IN IP4 10.22.0.93\r\ns=Asterisk\r\nc=IN IP4 10.22.0.93\r\nt=0 0\r\nm=audio 15330 RTP/AVP 0 8 18 101\r\na=rtpmap:0 PCMU/8000\r\na=rtpmap:8 PCMA/8000\r\na=rtpmap:18 G729/8000\r\na=fmtp:18 annexb=no\r\na=rtpmap:101 telephone-event/8000\r\na=fmtp:101 0-16\r\na=ptime:20\r\na=maxptime:150\r\na=sendrecv\r\n",
              "MessageDate": "Apr 24, 2024 12:14:27 PM",
              "SrcIpPort": "10.22.0.93:5090",
              "DstIpPort": "10.235.13.37:5060",
              "Transport": "UDP"
            },
            {
              "From": "02127090522",
              "To": "02242705000",
              "CallId": "cd76975f-9416-4120-88fd-80b779af27bf",
              "Request": "",
              "Response": 100,
              "Message": "SIP/2.0 100 Trying\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport=5090;received=10.22.0.93;branch=z9hG4bKPj1a458857-7939-417d-b80b-cadb2788efad\r\nFrom: \"VOYCEDialer\" <sip:02127090522@10.22.0.93>;tag=33193002-9ea2-4b48-8312-2c6a12af0f60\r\nTo: <sip:02242705000@10.235.13.37>\r\nCall-ID: cd76975f-9416-4120-88fd-80b779af27bf\r\nCSeq: 2194 INVITE\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:14:27 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090522",
              "To": "02242705000",
              "CallId": "cd76975f-9416-4120-88fd-80b779af27bf",
              "Request": "",
              "Response": 200,
              "Message": "SIP/2.0 200 OK\r\nSession-Expires: 1800;refresher=uas\r\nRequire: timer\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport=5090;received=10.22.0.93;branch=z9hG4bKPj1a458857-7939-417d-b80b-cadb2788efad\r\nTo: <sip:02242705000@10.235.13.37>;tag=3922938867-1572864842\r\nFrom: \"VOYCEDialer\" <sip:02127090522@10.22.0.93>;tag=33193002-9ea2-4b48-8312-2c6a12af0f60\r\nCall-ID: cd76975f-9416-4120-88fd-80b779af27bf\r\nCSeq: 2194 INVITE\r\nAllow: PUBLISH, MESSAGE, UPDATE, PRACK, SUBSCRIBE, REFER, INFO, NOTIFY, REGISTER, OPTIONS, BYE, INVITE, ACK, CANCEL\r\nContact: <sip:02242705000@10.235.13.37:5060>\r\nContent-Type: application/sdp\r\nAccept: application/sdp\r\nAllow-Events: message-summary, refer, dialog, line-seize, presence, call-info, as-feature-event, calling-name, ua-profile\r\nContent-Length: 182\r\n\r\nv=0\r\no=isnet-sbc-kule2 258116212 258116213 IN IP4 10.235.13.37\r\ns=sip call\r\nc=IN IP4 10.235.13.36\r\nt=0 0\r\nm=audio 25466 RTP/AVP 0 101\r\na=rtpmap:101 telephone-event/8000\r\na=ptime:20\r\n",
              "MessageDate": "Apr 24, 2024 12:14:27 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090522",
              "To": "02242705000",
              "CallId": "cd76975f-9416-4120-88fd-80b779af27bf",
              "Request": "ACK",
              "Response": 0,
              "Message": "ACK sip:02242705000@10.235.13.37:5060 SIP/2.0\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport;branch=z9hG4bKPjf21f7515-e314-4bbb-addb-4b65dc98b11b\r\nFrom: \"VOYCEDialer\" <sip:02127090522@10.22.0.93>;tag=33193002-9ea2-4b48-8312-2c6a12af0f60\r\nTo: <sip:02242705000@10.235.13.37>;tag=3922938867-1572864842\r\nCall-ID: cd76975f-9416-4120-88fd-80b779af27bf\r\nCSeq: 2194 ACK\r\nMax-Forwards: 70\r\nUser-Agent: Asterisk PBX 18.14.0\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:14:27 PM",
              "SrcIpPort": "10.22.0.93:5090",
              "DstIpPort": "10.235.13.37:5060",
              "Transport": "UDP"
            },
            {
              "From": "02127090522",
              "To": "02242705000",
              "CallId": "cd76975f-9416-4120-88fd-80b779af27bf",
              "Request": "BYE",
              "Response": 0,
              "Message": "BYE sip:02242705000@10.235.13.37:5060 SIP/2.0\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport;branch=z9hG4bKPj209b0f4e-453c-40e0-81c9-01f557927c5a\r\nFrom: \"VOYCEDialer\" <sip:02127090522@10.22.0.93>;tag=33193002-9ea2-4b48-8312-2c6a12af0f60\r\nTo: <sip:02242705000@10.235.13.37>;tag=3922938867-1572864842\r\nCall-ID: cd76975f-9416-4120-88fd-80b779af27bf\r\nCSeq: 2195 BYE\r\nReason: Q.850;cause=16\r\nMax-Forwards: 70\r\nUser-Agent: Asterisk PBX 18.14.0\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:14:35 PM",
              "SrcIpPort": "10.22.0.93:5090",
              "DstIpPort": "10.235.13.37:5060",
              "Transport": "UDP"
            }
          ],
          "SpeechRules": [
            {
              "Id": "6628c4a31cc8fe45e6d5ea30",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:15:15.798Z",
              "Result": false
            },
            {
              "Id": "6628c47f1cc8fe45e6d5ea2c",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:15:15.799Z",
              "Result": false
            },
            {
              "Id": "65b7670df86bc254da42fd4a",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:15:15.799Z",
              "Result": false
            },
            {
              "Id": "6628c4c91cc8fe45e6d5ea3c",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:15:15.799Z",
              "Result": false
            }
          ]
        },
        {
          "_id": "6628cd4a754252a369b45c26",
          "Station": {
            "No": 3,
            "Extension": "02127090512",
            "IsConferenceRoom": false,
            "Inbound": true,
            "Outbound": true,
            "Speech": true,
            "Stereo": false,
            "Encrypt": false,
            "Encode": true,
            "Qm": true,
            "Name": "ethem.tumba_collecturk",
            "Description": "ethem.tumba_collecturk",
            "SwitchId": "Santral",
            "PbxId": "ethem.tumba_collecturk",
            "RecognitionServerId": "6627a93bd050e39e274de76c",
            "Model": "tr-8",
            "Organization": "collecturk",
            "OrganizationId": "6627a8ebd050e39e274de764"
          },
          "RecorderName": "Kayıt Sunucusu",
          "MachineName": "colvycstt",
          "CallId": "8ab727e9-0079-42cb-b621-3eb1711eaa88",
          "SipCallId": "b8f4be32-f7b2-4fa5-9567-043a42e2521f",
          "PbxCallId": "AjTnAiJAUD6n9EWdrfeRG",
          "StartDate": "2024-04-24T09:13:09.000Z",
          "StopDate": "2024-04-24T09:13:46.000Z",
          "Duration": 37,
          "PhoneNumber": "02324620464",
          "Inbound": true,
          "HasVideo": false,
          "VideoCodecIn": "",
          "VideoCodecOut": "",
          "HoldCount": 0,
          "HoldDuration": 0,
          "TerminateReason": 1,
          "SipKey": "X-VoyceID",
          "SipKeyValue": "Yanlış Numara",
          "QueueName": "collecturk_queue_dunya_varlik",
          "QueueExtension": "Dünya Varlık",
          "CtiDatas": [
            {
              "PhoneNumber": "02324620464",
              "PbxId": "ethem.tumba_collecturk",
              "CtiCallId": "b8f4be32-f7b2-4fa5-9567-043a42e2521f",
              "Queue": "Dünya Varlık",
              "CustomDatas": [
                "CustomerNo=24766615",
                "CustomerName=İDRİS YILMAZ"
              ],
              "Date": "2024-04-24T09:13:09.000Z",
              "CtiEvent": 0,
              "Direction": 0,
              "TerminateReason": 0,
              "ControlPhoneNumber": false,
              "MatchWithSipCallId": true
            }
          ],
          "Attachments": [],
          "MediaLog": {
            "RtpPacketCountIn": 1763,
            "RtpPacketCountOut": 1889,
            "DublicateRtpPacketCountIn": 0,
            "DublicateRtpPacketCountOut": 0,
            "LostRtpPacketCountIn": 4,
            "LostRtpPacketCountOut": 0,
            "FirstRtpPacketIn": "Apr 24, 2024 12:13:07 PM",
            "FirstRtpPacketOut": "Apr 24, 2024 12:13:08 PM",
            "LastRtpPacketIn": "Apr 24, 2024 12:13:43 PM",
            "LastRtpPacketOut": "Apr 24, 2024 12:13:46 PM",
            "CodecIn": [],
            "CodecOut": [],
            "RtpEncryptionIn": false,
            "RtpEncryptionOut": false,
            "SourcesIn": [
              {
                "IpPortLen": "10.235.13.36-24948 -> 10.22.0.93-19964-180",
                "Count": 1763,
                "FirstDate": "Apr 24, 2024 12:13:07 PM",
                "LastDate": "Apr 24, 2024 12:13:43 PM",
                "a": "10.235.13.36",
                "b": 24948,
                "c": "10.22.0.93",
                "d": 19964
              },
              {
                "IpPortLen": "10.235.13.36-24948 -> 10.22.0.93-19964-21",
                "Count": 6,
                "FirstDate": "Apr 24, 2024 12:13:42 PM",
                "LastDate": "Apr 24, 2024 12:13:43 PM",
                "a": "10.235.13.36",
                "b": 24948,
                "c": "10.22.0.93",
                "d": 19964
              }
            ],
            "SourcesOut": [
              {
                "IpPortLen": "10.22.0.93-19964 -> 10.235.13.36-24948-180",
                "Count": 1894,
                "FirstDate": "Apr 24, 2024 12:13:08 PM",
                "LastDate": "Apr 24, 2024 12:13:46 PM",
                "a": "10.22.0.93",
                "b": 19964,
                "c": "10.235.13.36",
                "d": 24948
              }
            ]
          },
          "SignalingLogs": [
            {
              "From": "02127090512",
              "To": "02324620464",
              "CallId": "b8f4be32-f7b2-4fa5-9567-043a42e2521f",
              "Request": "INVITE",
              "Response": 0,
              "Message": "INVITE sip:02324620464@10.235.13.37:5060 SIP/2.0\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport;branch=z9hG4bKPj1a27e483-367a-4942-944e-d5ca185d68b9\r\nFrom: \"VOYCEDialer\" <sip:02127090512@10.22.0.93>;tag=cc8e5edb-5172-40ed-a537-f426de09bb2f\r\nTo: <sip:02324620464@10.235.13.37>\r\nContact: <sip:asterisk@10.22.0.93:5090>\r\nCall-ID: b8f4be32-f7b2-4fa5-9567-043a42e2521f\r\nCSeq: 27577 INVITE\r\nAllow: OPTIONS,  REGISTER,  SUBSCRIBE,  NOTIFY,  PUBLISH,  INVITE,  ACK,  BYE,  CANCEL,  UPDATE,  PRACK,  MESSAGE,  REFER\r\nSupported: 100rel, timer, replaces, norefersub, histinfo\r\nSession-Expires: 1800\r\nMin-SE: 90\r\nX-Extension: VOYCEDialer\r\nP-Early-Media: supported\r\nMax-Forwards: 70\r\nUser-Agent: Asterisk PBX 18.14.0\r\nContent-Type: application/sdp\r\nContent-Length: 304\r\n\r\nv=0\r\no=- 1926894921 1926894921 IN IP4 10.22.0.93\r\ns=Asterisk\r\nc=IN IP4 10.22.0.93\r\nt=0 0\r\nm=audio 19964 RTP/AVP 0 8 18 101\r\na=rtpmap:0 PCMU/8000\r\na=rtpmap:8 PCMA/8000\r\na=rtpmap:18 G729/8000\r\na=fmtp:18 annexb=no\r\na=rtpmap:101 telephone-event/8000\r\na=fmtp:101 0-16\r\na=ptime:20\r\na=maxptime:150\r\na=sendrecv\r\n",
              "MessageDate": "Apr 24, 2024 12:13:03 PM",
              "SrcIpPort": "10.22.0.93:5090",
              "DstIpPort": "10.235.13.37:5060",
              "Transport": "UDP"
            },
            {
              "From": "02127090512",
              "To": "02324620464",
              "CallId": "b8f4be32-f7b2-4fa5-9567-043a42e2521f",
              "Request": "",
              "Response": 100,
              "Message": "SIP/2.0 100 Trying\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport=5090;received=10.22.0.93;branch=z9hG4bKPj1a27e483-367a-4942-944e-d5ca185d68b9\r\nFrom: \"VOYCEDialer\" <sip:02127090512@10.22.0.93>;tag=cc8e5edb-5172-40ed-a537-f426de09bb2f\r\nTo: <sip:02324620464@10.235.13.37>\r\nCall-ID: b8f4be32-f7b2-4fa5-9567-043a42e2521f\r\nCSeq: 27577 INVITE\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:13:03 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090512",
              "To": "02324620464",
              "CallId": "b8f4be32-f7b2-4fa5-9567-043a42e2521f",
              "Request": "",
              "Response": 180,
              "Message": "SIP/2.0 180 Ringing\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport=5090;received=10.22.0.93;branch=z9hG4bKPj1a27e483-367a-4942-944e-d5ca185d68b9\r\nTo: <sip:02324620464@10.235.13.37>;tag=3922938783-1327077134\r\nFrom: \"VOYCEDialer\" <sip:02127090512@10.22.0.93>;tag=cc8e5edb-5172-40ed-a537-f426de09bb2f\r\nCall-ID: b8f4be32-f7b2-4fa5-9567-043a42e2521f\r\nCSeq: 27577 INVITE\r\nAllow: PUBLISH, MESSAGE, UPDATE, PRACK, SUBSCRIBE, REFER, INFO, NOTIFY, REGISTER, OPTIONS, BYE, INVITE, ACK, CANCEL\r\nContact: <sip:02324620464@10.235.13.37:5060>\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:13:03 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090512",
              "To": "02324620464",
              "CallId": "b8f4be32-f7b2-4fa5-9567-043a42e2521f",
              "Request": "",
              "Response": 200,
              "Message": "SIP/2.0 200 OK\r\nSession-Expires: 1800;refresher=uas\r\nRequire: timer\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport=5090;received=10.22.0.93;branch=z9hG4bKPj1a27e483-367a-4942-944e-d5ca185d68b9\r\nTo: <sip:02324620464@10.235.13.37>;tag=3922938783-1327077134\r\nFrom: \"VOYCEDialer\" <sip:02127090512@10.22.0.93>;tag=cc8e5edb-5172-40ed-a537-f426de09bb2f\r\nCall-ID: b8f4be32-f7b2-4fa5-9567-043a42e2521f\r\nCSeq: 27577 INVITE\r\nAllow: PUBLISH, MESSAGE, UPDATE, PRACK, SUBSCRIBE, REFER, INFO, NOTIFY, REGISTER, OPTIONS, BYE, INVITE, ACK, CANCEL\r\nContact: <sip:02324620464@10.235.13.37:5060>\r\nContent-Type: application/sdp\r\nAccept: application/sdp\r\nAllow-Events: message-summary, refer, dialog, line-seize, presence, call-info, as-feature-event, calling-name, ua-profile\r\nContent-Length: 182\r\n\r\nv=0\r\no=isnet-sbc-kule2 258032187 258032188 IN IP4 10.235.13.37\r\ns=sip call\r\nc=IN IP4 10.235.13.36\r\nt=0 0\r\nm=audio 24948 RTP/AVP 8 101\r\na=rtpmap:101 telephone-event/8000\r\na=ptime:20\r\n",
              "MessageDate": "Apr 24, 2024 12:13:07 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090512",
              "To": "02324620464",
              "CallId": "b8f4be32-f7b2-4fa5-9567-043a42e2521f",
              "Request": "ACK",
              "Response": 0,
              "Message": "ACK sip:02324620464@10.235.13.37:5060 SIP/2.0\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport;branch=z9hG4bKPj67b081c3-277a-4be1-9244-6628699a0688\r\nFrom: \"VOYCEDialer\" <sip:02127090512@10.22.0.93>;tag=cc8e5edb-5172-40ed-a537-f426de09bb2f\r\nTo: <sip:02324620464@10.235.13.37>;tag=3922938783-1327077134\r\nCall-ID: b8f4be32-f7b2-4fa5-9567-043a42e2521f\r\nCSeq: 27577 ACK\r\nMax-Forwards: 70\r\nUser-Agent: Asterisk PBX 18.14.0\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:13:07 PM",
              "SrcIpPort": "10.22.0.93:5090",
              "DstIpPort": "10.235.13.37:5060",
              "Transport": "UDP"
            },
            {
              "From": "02324620464",
              "To": "02127090512",
              "CallId": "b8f4be32-f7b2-4fa5-9567-043a42e2521f",
              "Request": "INVITE",
              "Response": 0,
              "Message": "INVITE sip:asterisk@10.22.0.93:5090 SIP/2.0\r\nMax-Forwards: 69\r\nSession-Expires: 1800;refresher=uac\r\nMin-SE: 600\r\nSupported: timer, 100rel\r\nTo: \"VOYCEDialer\" <sip:02127090512@10.22.0.93>;tag=cc8e5edb-5172-40ed-a537-f426de09bb2f\r\nFrom: <sip:02324620464@10.235.13.37>;tag=3922938783-1327077134\r\nCall-ID: b8f4be32-f7b2-4fa5-9567-043a42e2521f\r\nCSeq: 2 INVITE\r\nAllow: PUBLISH, MESSAGE, UPDATE, PRACK, SUBSCRIBE, REFER, INFO, NOTIFY, REGISTER, OPTIONS, BYE, INVITE, ACK, CANCEL\r\nVia: SIP/2.0/UDP 10.235.13.37:5060;branch=z9hG4bK5c3ed93037870b33dc7f7f0ccae34dd7\r\nContact: <sip:02324620464@10.235.13.37:5060>\r\nContent-Type: application/sdp\r\nAccept: application/sdp\r\nAllow-Events: message-summary, refer, dialog, line-seize, presence, call-info, as-feature-event, calling-name, ua-profile\r\nContent-Length: 182\r\n\r\nv=0\r\no=isnet-sbc-kule2 258032187 258032189 IN IP4 10.235.13.37\r\ns=sip call\r\nc=IN IP4 10.235.13.36\r\nt=0 0\r\nm=audio 24948 RTP/AVP 8 101\r\na=rtpmap:101 telephone-event/8000\r\na=ptime:20\r\n",
              "MessageDate": "Apr 24, 2024 12:13:43 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02324620464",
              "To": "02127090512",
              "CallId": "b8f4be32-f7b2-4fa5-9567-043a42e2521f",
              "Request": "",
              "Response": 200,
              "Message": "SIP/2.0 200 OK\r\nVia: SIP/2.0/UDP 10.235.13.37:5060;rport=5060;received=10.235.13.37;branch=z9hG4bK5c3ed93037870b33dc7f7f0ccae34dd7\r\nCall-ID: b8f4be32-f7b2-4fa5-9567-043a42e2521f\r\nFrom: <sip:02324620464@10.235.13.37>;tag=3922938783-1327077134\r\nTo: \"VOYCEDialer\" <sip:02127090512@10.22.0.93>;tag=cc8e5edb-5172-40ed-a537-f426de09bb2f\r\nCSeq: 2 INVITE\r\nSession-Expires: 1800;refresher=uac\r\nRequire: timer\r\nContact: <sip:asterisk@10.22.0.93:5090>\r\nAllow: OPTIONS,  REGISTER,  SUBSCRIBE,  NOTIFY,  PUBLISH,  INVITE,  ACK,  BYE,  CANCEL,  UPDATE,  PRACK,  MESSAGE,  REFER\r\nSupported: 100rel, timer, replaces, norefersub\r\nServer: Asterisk PBX 18.14.0\r\nContent-Type: application/sdp\r\nContent-Length: 233\r\n\r\nv=0\r\no=- 1926894921 1926894922 IN IP4 10.22.0.93\r\ns=Asterisk\r\nc=IN IP4 10.22.0.93\r\nt=0 0\r\nm=audio 19964 RTP/AVP 8 101\r\na=rtpmap:8 PCMA/8000\r\na=rtpmap:101 telephone-event/8000\r\na=fmtp:101 0-16\r\na=ptime:20\r\na=maxptime:150\r\na=sendrecv\r\n",
              "MessageDate": "Apr 24, 2024 12:13:43 PM",
              "SrcIpPort": "10.22.0.93:5090",
              "DstIpPort": "10.235.13.37:5060",
              "Transport": "UDP"
            },
            {
              "From": "02324620464",
              "To": "02127090512",
              "CallId": "b8f4be32-f7b2-4fa5-9567-043a42e2521f",
              "Request": "ACK",
              "Response": 0,
              "Message": "ACK sip:asterisk@10.22.0.93:5090 SIP/2.0\r\nMax-Forwards: 69\r\nTo: \"VOYCEDialer\" <sip:02127090512@10.22.0.93>;tag=cc8e5edb-5172-40ed-a537-f426de09bb2f\r\nFrom: <sip:02324620464@10.235.13.37>;tag=3922938783-1327077134\r\nCall-ID: b8f4be32-f7b2-4fa5-9567-043a42e2521f\r\nCSeq: 2 ACK\r\nVia: SIP/2.0/UDP 10.235.13.37:5060;branch=z9hG4bKfd6149e01499cb8a335bc664cbff2736\r\nContact: <sip:02324620464@10.235.13.37:5060>\r\nAllow-Events: message-summary, refer, dialog, line-seize, presence, call-info, as-feature-event, calling-name, ua-profile\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:13:43 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090512",
              "To": "02324620464",
              "CallId": "b8f4be32-f7b2-4fa5-9567-043a42e2521f",
              "Request": "BYE",
              "Response": 0,
              "Message": "BYE sip:02324620464@10.235.13.37:5060 SIP/2.0\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport;branch=z9hG4bKPjc973a97b-9bfd-4c83-9779-a30cb3cc6586\r\nFrom: \"VOYCEDialer\" <sip:02127090512@10.22.0.93>;tag=cc8e5edb-5172-40ed-a537-f426de09bb2f\r\nTo: <sip:02324620464@10.235.13.37>;tag=3922938783-1327077134\r\nCall-ID: b8f4be32-f7b2-4fa5-9567-043a42e2521f\r\nCSeq: 27578 BYE\r\nReason: Q.850;cause=16\r\nMax-Forwards: 70\r\nUser-Agent: Asterisk PBX 18.14.0\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:13:46 PM",
              "SrcIpPort": "10.22.0.93:5090",
              "DstIpPort": "10.235.13.37:5060",
              "Transport": "UDP"
            }
          ],
          "SpeechRules": [
            {
              "Id": "6628c4a31cc8fe45e6d5ea30",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:14:22.012Z",
              "Result": false
            },
            {
              "Id": "6628c47f1cc8fe45e6d5ea2c",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:14:22.014Z",
              "Result": false
            },
            {
              "Id": "65b7670df86bc254da42fd4a",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:14:22.027Z",
              "Result": false
            },
            {
              "Id": "6628c4c91cc8fe45e6d5ea3c",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:14:22.034Z",
              "Result": false
            }
          ]
        },
        {
          "_id": "6628cd11754252a369b45c25",
          "Station": {
            "No": 9,
            "Extension": "02127090572",
            "IsConferenceRoom": false,
            "Inbound": true,
            "Outbound": true,
            "Speech": true,
            "Stereo": false,
            "Encrypt": false,
            "Encode": true,
            "Qm": true,
            "Name": "ethem.tumba_collecturk",
            "Description": "ethem.tumba_collecturk",
            "SwitchId": "Santral",
            "PbxId": "ethem.tumba_collecturk",
            "RecognitionServerId": "6627a93bd050e39e274de76c",
            "Model": "tr-8",
            "Organization": "collecturk",
            "OrganizationId": "6627a8ebd050e39e274de764"
          },
          "RecorderName": "Kayıt Sunucusu",
          "MachineName": "colvycstt",
          "CallId": "81417c88-8b9a-4b9a-a2b0-ba58c654cc6d",
          "SipCallId": "0c3c3d55-3fd9-42ae-b3fb-3eca7ae77684",
          "PbxCallId": "Xd6Gq3edijAmdtr99DPjE",
          "StartDate": "2024-04-24T09:11:52.000Z",
          "StopDate": "2024-04-24T09:12:49.000Z",
          "Duration": 57,
          "PhoneNumber": "05069045970",
          "Inbound": true,
          "HasVideo": false,
          "VideoCodecIn": "",
          "VideoCodecOut": "",
          "HoldCount": 0,
          "HoldDuration": 0,
          "TerminateReason": 1,
          "SipKey": "X-VoyceID",
          "SipKeyValue": "Not Bırakıldı",
          "QueueName": "collecturk_queue_dunya_varlik",
          "QueueExtension": "Dünya Varlık",
          "CtiDatas": [
            {
              "PhoneNumber": "05069045970",
              "PbxId": "ethem.tumba_collecturk",
              "CtiCallId": "0c3c3d55-3fd9-42ae-b3fb-3eca7ae77684",
              "Queue": "Dünya Varlık",
              "CustomDatas": [
                "CustomerNo=23592116",
                "CustomerName=TAMER DİKER"
              ],
              "Date": "2024-04-24T09:11:52.000Z",
              "CtiEvent": 0,
              "Direction": 0,
              "TerminateReason": 0,
              "ControlPhoneNumber": false,
              "MatchWithSipCallId": true
            }
          ],
          "Attachments": [],
          "MediaLog": {
            "RtpPacketCountIn": 3014,
            "RtpPacketCountOut": 2939,
            "DublicateRtpPacketCountIn": 0,
            "DublicateRtpPacketCountOut": 0,
            "LostRtpPacketCountIn": 0,
            "LostRtpPacketCountOut": 0,
            "FirstRtpPacketIn": "Apr 24, 2024 12:11:49 PM",
            "FirstRtpPacketOut": "Apr 24, 2024 12:11:50 PM",
            "LastRtpPacketIn": "Apr 24, 2024 12:12:49 PM",
            "LastRtpPacketOut": "Apr 24, 2024 12:12:49 PM",
            "CodecIn": [],
            "CodecOut": [],
            "RtpEncryptionIn": false,
            "RtpEncryptionOut": false,
            "SourcesIn": [
              {
                "IpPortLen": "10.235.13.36-24428 -> 10.22.0.93-10700-180",
                "Count": 3009,
                "FirstDate": "Apr 24, 2024 12:11:49 PM",
                "LastDate": "Apr 24, 2024 12:12:49 PM",
                "a": "10.235.13.36",
                "b": 24428,
                "c": "10.22.0.93",
                "d": 10700
              },
              {
                "IpPortLen": "10.235.13.36-24428 -> 10.22.0.93-10700-24",
                "Count": 5,
                "FirstDate": "Apr 24, 2024 12:12:23 PM",
                "LastDate": "Apr 24, 2024 12:12:23 PM",
                "a": "10.235.13.36",
                "b": 24428,
                "c": "10.22.0.93",
                "d": 10700
              }
            ],
            "SourcesOut": [
              {
                "IpPortLen": "10.22.0.93-10700 -> 10.235.13.36-24428-180",
                "Count": 2939,
                "FirstDate": "Apr 24, 2024 12:11:50 PM",
                "LastDate": "Apr 24, 2024 12:12:49 PM",
                "a": "10.22.0.93",
                "b": 10700,
                "c": "10.235.13.36",
                "d": 24428
              }
            ]
          },
          "SignalingLogs": [
            {
              "From": "02127090572",
              "To": "05069045970",
              "CallId": "0c3c3d55-3fd9-42ae-b3fb-3eca7ae77684",
              "Request": "INVITE",
              "Response": 0,
              "Message": "INVITE sip:05069045970@10.235.13.37:5060 SIP/2.0\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport;branch=z9hG4bKPjdc0c0324-2f22-4c0a-af6c-0dfadc4e44d3\r\nFrom: \"VOYCEDialer\" <sip:02127090572@10.22.0.93>;tag=d66e38cc-5d92-46c6-a8cd-d797c3171bcb\r\nTo: <sip:05069045970@10.235.13.37>\r\nContact: <sip:asterisk@10.22.0.93:5090>\r\nCall-ID: 0c3c3d55-3fd9-42ae-b3fb-3eca7ae77684\r\nCSeq: 27658 INVITE\r\nAllow: OPTIONS,  REGISTER,  SUBSCRIBE,  NOTIFY,  PUBLISH,  INVITE,  ACK,  BYE,  CANCEL,  UPDATE,  PRACK,  MESSAGE,  REFER\r\nSupported: 100rel, timer, replaces, norefersub, histinfo\r\nSession-Expires: 1800\r\nMin-SE: 90\r\nX-Extension: VOYCEDialer\r\nP-Early-Media: supported\r\nMax-Forwards: 70\r\nUser-Agent: Asterisk PBX 18.14.0\r\nContent-Type: application/sdp\r\nContent-Length: 302\r\n\r\nv=0\r\no=- 577718126 577718126 IN IP4 10.22.0.93\r\ns=Asterisk\r\nc=IN IP4 10.22.0.93\r\nt=0 0\r\nm=audio 10700 RTP/AVP 0 8 18 101\r\na=rtpmap:0 PCMU/8000\r\na=rtpmap:8 PCMA/8000\r\na=rtpmap:18 G729/8000\r\na=fmtp:18 annexb=no\r\na=rtpmap:101 telephone-event/8000\r\na=fmtp:101 0-16\r\na=ptime:20\r\na=maxptime:150\r\na=sendrecv\r\n",
              "MessageDate": "Apr 24, 2024 12:11:39 PM",
              "SrcIpPort": "10.22.0.93:5090",
              "DstIpPort": "10.235.13.37:5060",
              "Transport": "UDP"
            },
            {
              "From": "02127090572",
              "To": "05069045970",
              "CallId": "0c3c3d55-3fd9-42ae-b3fb-3eca7ae77684",
              "Request": "",
              "Response": 100,
              "Message": "SIP/2.0 100 Trying\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport=5090;received=10.22.0.93;branch=z9hG4bKPjdc0c0324-2f22-4c0a-af6c-0dfadc4e44d3\r\nFrom: \"VOYCEDialer\" <sip:02127090572@10.22.0.93>;tag=d66e38cc-5d92-46c6-a8cd-d797c3171bcb\r\nTo: <sip:05069045970@10.235.13.37>\r\nCall-ID: 0c3c3d55-3fd9-42ae-b3fb-3eca7ae77684\r\nCSeq: 27658 INVITE\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:11:39 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090572",
              "To": "05069045970",
              "CallId": "0c3c3d55-3fd9-42ae-b3fb-3eca7ae77684",
              "Request": "",
              "Response": 183,
              "Message": "SIP/2.0 183 Session Progress\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport=5090;received=10.22.0.93;branch=z9hG4bKPjdc0c0324-2f22-4c0a-af6c-0dfadc4e44d3\r\nTo: <sip:05069045970@10.235.13.37>;tag=3922938702-1400712942\r\nFrom: \"VOYCEDialer\" <sip:02127090572@10.22.0.93>;tag=d66e38cc-5d92-46c6-a8cd-d797c3171bcb\r\nCall-ID: 0c3c3d55-3fd9-42ae-b3fb-3eca7ae77684\r\nCSeq: 27658 INVITE\r\nAllow: PUBLISH, MESSAGE, UPDATE, PRACK, SUBSCRIBE, REFER, INFO, NOTIFY, REGISTER, OPTIONS, BYE, INVITE, ACK, CANCEL\r\nContact: <sip:05069045970@10.235.13.37:5060>\r\nContent-Type: application/sdp\r\nContent-Length: 182\r\n\r\nv=0\r\no=isnet-sbc-kule2 257948258 257948259 IN IP4 10.235.13.37\r\ns=sip call\r\nc=IN IP4 10.235.13.36\r\nt=0 0\r\nm=audio 24428 RTP/AVP 0 101\r\na=rtpmap:101 telephone-event/8000\r\na=ptime:20\r\n",
              "MessageDate": "Apr 24, 2024 12:11:42 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090572",
              "To": "05069045970",
              "CallId": "0c3c3d55-3fd9-42ae-b3fb-3eca7ae77684",
              "Request": "",
              "Response": 200,
              "Message": "SIP/2.0 200 OK\r\nSession-Expires: 1800;refresher=uas\r\nRequire: timer\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport=5090;received=10.22.0.93;branch=z9hG4bKPjdc0c0324-2f22-4c0a-af6c-0dfadc4e44d3\r\nTo: <sip:05069045970@10.235.13.37>;tag=3922938702-1400712942\r\nFrom: \"VOYCEDialer\" <sip:02127090572@10.22.0.93>;tag=d66e38cc-5d92-46c6-a8cd-d797c3171bcb\r\nCall-ID: 0c3c3d55-3fd9-42ae-b3fb-3eca7ae77684\r\nCSeq: 27658 INVITE\r\nAllow: PUBLISH, MESSAGE, UPDATE, PRACK, SUBSCRIBE, REFER, INFO, NOTIFY, REGISTER, OPTIONS, BYE, INVITE, ACK, CANCEL\r\nContact: <sip:05069045970@10.235.13.37:5060>\r\nContent-Type: application/sdp\r\nAccept: application/sdp\r\nAllow-Events: message-summary, refer, dialog, line-seize, presence, call-info, as-feature-event, calling-name, ua-profile\r\nContent-Length: 182\r\n\r\nv=0\r\no=isnet-sbc-kule2 257948258 257948259 IN IP4 10.235.13.37\r\ns=sip call\r\nc=IN IP4 10.235.13.36\r\nt=0 0\r\nm=audio 24428 RTP/AVP 0 101\r\na=rtpmap:101 telephone-event/8000\r\na=ptime:20\r\n",
              "MessageDate": "Apr 24, 2024 12:11:49 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090572",
              "To": "05069045970",
              "CallId": "0c3c3d55-3fd9-42ae-b3fb-3eca7ae77684",
              "Request": "ACK",
              "Response": 0,
              "Message": "ACK sip:05069045970@10.235.13.37:5060 SIP/2.0\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport;branch=z9hG4bKPj7277d72b-fe75-4694-8431-711e2ef61533\r\nFrom: \"VOYCEDialer\" <sip:02127090572@10.22.0.93>;tag=d66e38cc-5d92-46c6-a8cd-d797c3171bcb\r\nTo: <sip:05069045970@10.235.13.37>;tag=3922938702-1400712942\r\nCall-ID: 0c3c3d55-3fd9-42ae-b3fb-3eca7ae77684\r\nCSeq: 27658 ACK\r\nMax-Forwards: 70\r\nUser-Agent: Asterisk PBX 18.14.0\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:11:49 PM",
              "SrcIpPort": "10.22.0.93:5090",
              "DstIpPort": "10.235.13.37:5060",
              "Transport": "UDP"
            },
            {
              "From": "02127090572",
              "To": "05069045970",
              "CallId": "0c3c3d55-3fd9-42ae-b3fb-3eca7ae77684",
              "Request": "BYE",
              "Response": 0,
              "Message": "BYE sip:05069045970@10.235.13.37:5060 SIP/2.0\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport;branch=z9hG4bKPjf4e1e352-3e05-465b-b12a-a85db65073ef\r\nFrom: \"VOYCEDialer\" <sip:02127090572@10.22.0.93>;tag=d66e38cc-5d92-46c6-a8cd-d797c3171bcb\r\nTo: <sip:05069045970@10.235.13.37>;tag=3922938702-1400712942\r\nCall-ID: 0c3c3d55-3fd9-42ae-b3fb-3eca7ae77684\r\nCSeq: 27659 BYE\r\nReason: Q.850;cause=16\r\nMax-Forwards: 70\r\nUser-Agent: Asterisk PBX 18.14.0\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:12:49 PM",
              "SrcIpPort": "10.22.0.93:5090",
              "DstIpPort": "10.235.13.37:5060",
              "Transport": "UDP"
            }
          ],
          "SpeechRules": [
            {
              "Id": "6628c4a31cc8fe45e6d5ea30",
              "KeyWords": [
                "annesi.*"
              ],
              "ResultDate": "2024-04-24T09:13:34.390Z",
              "Result": false
            },
            {
              "Id": "6628c47f1cc8fe45e6d5ea2c",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:13:34.390Z",
              "Result": false
            },
            {
              "Id": "65b7670df86bc254da42fd4a",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:13:34.394Z",
              "Result": false
            },
            {
              "Id": "6628c4c91cc8fe45e6d5ea3c",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:13:34.396Z",
              "Result": false
            }
          ]
        },
        {
          "_id": "6628cc84754252a369b45c24",
          "Station": {
            "No": 9,
            "Extension": "02127090572",
            "IsConferenceRoom": false,
            "Inbound": true,
            "Outbound": true,
            "Speech": true,
            "Stereo": false,
            "Encrypt": false,
            "Encode": true,
            "Qm": true,
            "Name": "ethem.tumba_collecturk",
            "Description": "ethem.tumba_collecturk",
            "SwitchId": "Santral",
            "PbxId": "ethem.tumba_collecturk",
            "RecognitionServerId": "6627a93bd050e39e274de76c",
            "Model": "tr-8",
            "Organization": "collecturk",
            "OrganizationId": "6627a8ebd050e39e274de764"
          },
          "RecorderName": "Kayıt Sunucusu",
          "MachineName": "colvycstt",
          "CallId": "6ece824a-05b7-4909-b8ef-bd408c43a13d",
          "SipCallId": "bef7879c-b706-45dd-b779-3bcc8c77699f",
          "PbxCallId": "TRn46QweN3DQEMYRWR3Kx",
          "StartDate": "2024-04-24T09:09:43.000Z",
          "StopDate": "2024-04-24T09:10:28.000Z",
          "Duration": 45,
          "PhoneNumber": "04628412156",
          "Inbound": true,
          "HasVideo": false,
          "VideoCodecIn": "",
          "VideoCodecOut": "",
          "HoldCount": 0,
          "HoldDuration": 0,
          "TerminateReason": 0,
          "SipKey": "X-VoyceID",
          "SipKeyValue": "Not Bırakıldı",
          "QueueName": "collecturk_queue_dunya_varlik",
          "QueueExtension": "Dünya Varlık",
          "CtiDatas": [
            {
              "PhoneNumber": "04628412156",
              "PbxId": "ethem.tumba_collecturk",
              "CtiCallId": "bef7879c-b706-45dd-b779-3bcc8c77699f",
              "Queue": "Dünya Varlık",
              "CustomDatas": [
                "CustomerNo=418878",
                "CustomerName=VOLKAN OKAN ŞİRİN"
              ],
              "Date": "2024-04-24T09:09:43.000Z",
              "CtiEvent": 0,
              "Direction": 0,
              "TerminateReason": 0,
              "ControlPhoneNumber": false,
              "MatchWithSipCallId": true
            }
          ],
          "Attachments": [],
          "MediaLog": {
            "RtpPacketCountIn": 1111,
            "RtpPacketCountOut": 2290,
            "DublicateRtpPacketCountIn": 0,
            "DublicateRtpPacketCountOut": 0,
            "LostRtpPacketCountIn": 38,
            "LostRtpPacketCountOut": 0,
            "FirstRtpPacketIn": "Apr 24, 2024 12:09:41 PM",
            "FirstRtpPacketOut": "Apr 24, 2024 12:09:42 PM",
            "LastRtpPacketIn": "Apr 24, 2024 12:10:28 PM",
            "LastRtpPacketOut": "Apr 24, 2024 12:10:28 PM",
            "CodecIn": [],
            "CodecOut": [],
            "RtpEncryptionIn": false,
            "RtpEncryptionOut": false,
            "SourcesIn": [
              {
                "IpPortLen": "10.235.13.36-23558 -> 10.22.0.93-16762-180",
                "Count": 1111,
                "FirstDate": "Apr 24, 2024 12:09:41 PM",
                "LastDate": "Apr 24, 2024 12:10:28 PM",
                "a": "10.235.13.36",
                "b": 23558,
                "c": "10.22.0.93",
                "d": 16762
              },
              {
                "IpPortLen": "10.235.13.36-23558 -> 10.22.0.93-16762-21",
                "Count": 38,
                "FirstDate": "Apr 24, 2024 12:09:41 PM",
                "LastDate": "Apr 24, 2024 12:10:28 PM",
                "a": "10.235.13.36",
                "b": 23558,
                "c": "10.22.0.93",
                "d": 16762
              }
            ],
            "SourcesOut": [
              {
                "IpPortLen": "10.22.0.93-16762 -> 10.235.13.36-23558-180",
                "Count": 2290,
                "FirstDate": "Apr 24, 2024 12:09:42 PM",
                "LastDate": "Apr 24, 2024 12:10:28 PM",
                "a": "10.22.0.93",
                "b": 16762,
                "c": "10.235.13.36",
                "d": 23558
              }
            ]
          },
          "SignalingLogs": [
            {
              "From": "02127090572",
              "To": "04628412156",
              "CallId": "bef7879c-b706-45dd-b779-3bcc8c77699f",
              "Request": "INVITE",
              "Response": 0,
              "Message": "INVITE sip:04628412156@10.235.13.37:5060 SIP/2.0\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport;branch=z9hG4bKPj8f983936-5567-48b4-981a-4b72e54a4465\r\nFrom: \"VOYCEDialer\" <sip:02127090572@10.22.0.93>;tag=547bf4e1-b3c7-4b6a-9bde-a275971f6996\r\nTo: <sip:04628412156@10.235.13.37>\r\nContact: <sip:asterisk@10.22.0.93:5090>\r\nCall-ID: bef7879c-b706-45dd-b779-3bcc8c77699f\r\nCSeq: 23248 INVITE\r\nAllow: OPTIONS,  REGISTER,  SUBSCRIBE,  NOTIFY,  PUBLISH,  INVITE,  ACK,  BYE,  CANCEL,  UPDATE,  PRACK,  MESSAGE,  REFER\r\nSupported: 100rel, timer, replaces, norefersub, histinfo\r\nSession-Expires: 1800\r\nMin-SE: 90\r\nX-Extension: VOYCEDialer\r\nP-Early-Media: supported\r\nMax-Forwards: 70\r\nUser-Agent: Asterisk PBX 18.14.0\r\nContent-Type: application/sdp\r\nContent-Length: 304\r\n\r\nv=0\r\no=- 1130331925 1130331925 IN IP4 10.22.0.93\r\ns=Asterisk\r\nc=IN IP4 10.22.0.93\r\nt=0 0\r\nm=audio 16762 RTP/AVP 0 8 18 101\r\na=rtpmap:0 PCMU/8000\r\na=rtpmap:8 PCMA/8000\r\na=rtpmap:18 G729/8000\r\na=fmtp:18 annexb=no\r\na=rtpmap:101 telephone-event/8000\r\na=fmtp:101 0-16\r\na=ptime:20\r\na=maxptime:150\r\na=sendrecv\r\n",
              "MessageDate": "Apr 24, 2024 12:09:27 PM",
              "SrcIpPort": "10.22.0.93:5090",
              "DstIpPort": "10.235.13.37:5060",
              "Transport": "UDP"
            },
            {
              "From": "02127090572",
              "To": "04628412156",
              "CallId": "bef7879c-b706-45dd-b779-3bcc8c77699f",
              "Request": "",
              "Response": 100,
              "Message": "SIP/2.0 100 Trying\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport=5090;received=10.22.0.93;branch=z9hG4bKPj8f983936-5567-48b4-981a-4b72e54a4465\r\nFrom: \"VOYCEDialer\" <sip:02127090572@10.22.0.93>;tag=547bf4e1-b3c7-4b6a-9bde-a275971f6996\r\nTo: <sip:04628412156@10.235.13.37>\r\nCall-ID: bef7879c-b706-45dd-b779-3bcc8c77699f\r\nCSeq: 23248 INVITE\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:09:27 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090572",
              "To": "04628412156",
              "CallId": "bef7879c-b706-45dd-b779-3bcc8c77699f",
              "Request": "",
              "Response": 180,
              "Message": "SIP/2.0 180 Ringing\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport=5090;received=10.22.0.93;branch=z9hG4bKPj8f983936-5567-48b4-981a-4b72e54a4465\r\nTo: <sip:04628412156@10.235.13.37>;tag=3922938567-1912493786\r\nFrom: \"VOYCEDialer\" <sip:02127090572@10.22.0.93>;tag=547bf4e1-b3c7-4b6a-9bde-a275971f6996\r\nCall-ID: bef7879c-b706-45dd-b779-3bcc8c77699f\r\nCSeq: 23248 INVITE\r\nAllow: PUBLISH, MESSAGE, UPDATE, PRACK, SUBSCRIBE, REFER, INFO, NOTIFY, REGISTER, OPTIONS, BYE, INVITE, ACK, CANCEL\r\nContact: <sip:04628412156@10.235.13.37:5060>\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:09:27 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090572",
              "To": "04628412156",
              "CallId": "bef7879c-b706-45dd-b779-3bcc8c77699f",
              "Request": "",
              "Response": 200,
              "Message": "SIP/2.0 200 OK\r\nSession-Expires: 1800;refresher=uas\r\nRequire: timer\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport=5090;received=10.22.0.93;branch=z9hG4bKPj8f983936-5567-48b4-981a-4b72e54a4465\r\nTo: <sip:04628412156@10.235.13.37>;tag=3922938567-1912493786\r\nFrom: \"VOYCEDialer\" <sip:02127090572@10.22.0.93>;tag=547bf4e1-b3c7-4b6a-9bde-a275971f6996\r\nCall-ID: bef7879c-b706-45dd-b779-3bcc8c77699f\r\nCSeq: 23248 INVITE\r\nAllow: PUBLISH, MESSAGE, UPDATE, PRACK, SUBSCRIBE, REFER, INFO, NOTIFY, REGISTER, OPTIONS, BYE, INVITE, ACK, CANCEL\r\nContact: <sip:04628412156@10.235.13.37:5060>\r\nContent-Type: application/sdp\r\nAccept: application/sdp\r\nAllow-Events: message-summary, refer, dialog, line-seize, presence, call-info, as-feature-event, calling-name, ua-profile\r\nContent-Length: 182\r\n\r\nv=0\r\no=isnet-sbc-kule2 257816338 257816339 IN IP4 10.235.13.37\r\ns=sip call\r\nc=IN IP4 10.235.13.36\r\nt=0 0\r\nm=audio 23558 RTP/AVP 8 101\r\na=rtpmap:101 telephone-event/8000\r\na=ptime:20\r\n",
              "MessageDate": "Apr 24, 2024 12:09:41 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090572",
              "To": "04628412156",
              "CallId": "bef7879c-b706-45dd-b779-3bcc8c77699f",
              "Request": "ACK",
              "Response": 0,
              "Message": "ACK sip:04628412156@10.235.13.37:5060 SIP/2.0\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport;branch=z9hG4bKPj89323453-af9d-41cb-a8c5-7a0220485bab\r\nFrom: \"VOYCEDialer\" <sip:02127090572@10.22.0.93>;tag=547bf4e1-b3c7-4b6a-9bde-a275971f6996\r\nTo: <sip:04628412156@10.235.13.37>;tag=3922938567-1912493786\r\nCall-ID: bef7879c-b706-45dd-b779-3bcc8c77699f\r\nCSeq: 23248 ACK\r\nMax-Forwards: 70\r\nUser-Agent: Asterisk PBX 18.14.0\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:09:41 PM",
              "SrcIpPort": "10.22.0.93:5090",
              "DstIpPort": "10.235.13.37:5060",
              "Transport": "UDP"
            },
            {
              "From": "04628412156",
              "To": "02127090572",
              "CallId": "bef7879c-b706-45dd-b779-3bcc8c77699f",
              "Request": "BYE",
              "Response": 0,
              "Message": "BYE sip:asterisk@10.22.0.93:5090 SIP/2.0\r\nMax-Forwards: 69\r\nTo: \"VOYCEDialer\" <sip:02127090572@10.22.0.93>;tag=547bf4e1-b3c7-4b6a-9bde-a275971f6996\r\nFrom: <sip:04628412156@10.235.13.37>;tag=3922938567-1912493786\r\nCall-ID: bef7879c-b706-45dd-b779-3bcc8c77699f\r\nCSeq: 2 BYE\r\nAllow: PUBLISH, MESSAGE, UPDATE, PRACK, SUBSCRIBE, REFER, INFO, NOTIFY, REGISTER, OPTIONS, BYE, INVITE, ACK, CANCEL\r\nVia: SIP/2.0/UDP 10.235.13.37:5060;branch=z9hG4bKcebd748f24e58814a7124e5ff3f57552\r\nAllow-Events: message-summary, refer, dialog, line-seize, presence, call-info, as-feature-event, calling-name, ua-profile\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:10:28 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            }
          ],
          "SpeechRules": [
            {
              "Id": "6628c4a31cc8fe45e6d5ea30",
              "KeyWords": [
                "akrabasıyım"
              ],
              "ResultDate": "2024-04-24T09:11:25.213Z",
              "Result": false
            },
            {
              "Id": "6628c47f1cc8fe45e6d5ea2c",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:11:25.213Z",
              "Result": false
            },
            {
              "Id": "65b7670df86bc254da42fd4a",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:11:25.216Z",
              "Result": false
            },
            {
              "Id": "6628c4c91cc8fe45e6d5ea3c",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:11:25.217Z",
              "Result": false
            }
          ]
        },
        {
          "_id": "6628cc2f754252a369b45c23",
          "Station": {
            "No": 1,
            "Extension": "02127090816",
            "IsConferenceRoom": false,
            "Inbound": true,
            "Outbound": true,
            "Speech": true,
            "Stereo": false,
            "Encrypt": false,
            "Encode": true,
            "Qm": true,
            "Name": "ethem.tumba_collecturk",
            "Description": "ethem.tumba_collecturk",
            "SwitchId": "Santral",
            "PbxId": "ethem.tumba_collecturk",
            "RecognitionServerId": "6627a93bd050e39e274de76c",
            "Model": "tr-8",
            "Organization": "collecturk",
            "OrganizationId": "6627a8ebd050e39e274de764"
          },
          "RecorderName": "Kayıt Sunucusu",
          "MachineName": "colvycstt",
          "CallId": "20897490-75f6-4b7f-9a31-da97c7cb0ab9",
          "SipCallId": "a1cc6002-546c-4ee6-8ad3-f7fb1873c63f",
          "PbxCallId": "ENwKGh8czghqfxBELrwfB",
          "StartDate": "2024-04-24T09:04:57.000Z",
          "StopDate": "2024-04-24T09:09:02.000Z",
          "Duration": 245,
          "PhoneNumber": "05432902910",
          "Inbound": true,
          "HasVideo": false,
          "VideoCodecIn": "",
          "VideoCodecOut": "",
          "HoldCount": 0,
          "HoldDuration": 0,
          "TerminateReason": 0,
          "SipKey": "X-VoyceID",
          "SipKeyValue": "Bilgi Verildi",
          "QueueName": "collecturk_queue_dunya_varlik",
          "QueueExtension": "Dünya Varlık",
          "CtiDatas": [
            {
              "PhoneNumber": "05432902910",
              "PbxId": "ethem.tumba_collecturk",
              "CtiCallId": "a1cc6002-546c-4ee6-8ad3-f7fb1873c63f",
              "Queue": "Dünya Varlık",
              "CustomDatas": [
                "CustomerNo=20890020",
                "CustomerName=CEVDET YATAR"
              ],
              "Date": "2024-04-24T09:04:56.000Z",
              "CtiEvent": 0,
              "Direction": 0,
              "TerminateReason": 0,
              "ControlPhoneNumber": false,
              "MatchWithSipCallId": true
            }
          ],
          "Attachments": [],
          "MediaLog": {
            "RtpPacketCountIn": 12813,
            "RtpPacketCountOut": 12738,
            "DublicateRtpPacketCountIn": 0,
            "DublicateRtpPacketCountOut": 0,
            "LostRtpPacketCountIn": 0,
            "LostRtpPacketCountOut": 0,
            "FirstRtpPacketIn": "Apr 24, 2024 12:04:46 PM",
            "FirstRtpPacketOut": "Apr 24, 2024 12:04:47 PM",
            "LastRtpPacketIn": "Apr 24, 2024 12:09:02 PM",
            "LastRtpPacketOut": "Apr 24, 2024 12:09:02 PM",
            "CodecIn": [],
            "CodecOut": [],
            "RtpEncryptionIn": false,
            "RtpEncryptionOut": false,
            "SourcesIn": [
              {
                "IpPortLen": "10.235.13.36-21472 -> 10.22.0.93-15156-180",
                "Count": 12637,
                "FirstDate": "Apr 24, 2024 12:04:46 PM",
                "LastDate": "Apr 24, 2024 12:09:02 PM",
                "a": "10.235.13.36",
                "b": 21472,
                "c": "10.22.0.93",
                "d": 15156
              },
              {
                "IpPortLen": "10.235.13.36-21472 -> 10.22.0.93-15156-24",
                "Count": 177,
                "FirstDate": "Apr 24, 2024 12:07:56 PM",
                "LastDate": "Apr 24, 2024 12:08:53 PM",
                "a": "10.235.13.36",
                "b": 21472,
                "c": "10.22.0.93",
                "d": 15156
              }
            ],
            "SourcesOut": [
              {
                "IpPortLen": "10.22.0.93-15156 -> 10.235.13.36-21472-180",
                "Count": 12740,
                "FirstDate": "Apr 24, 2024 12:04:47 PM",
                "LastDate": "Apr 24, 2024 12:09:02 PM",
                "a": "10.22.0.93",
                "b": 15156,
                "c": "10.235.13.36",
                "d": 21472
              }
            ]
          },
          "SignalingLogs": [
            {
              "From": "02127090816",
              "To": "05432902910",
              "CallId": "a1cc6002-546c-4ee6-8ad3-f7fb1873c63f",
              "Request": "INVITE",
              "Response": 0,
              "Message": "INVITE sip:05432902910@10.235.13.37:5060 SIP/2.0\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport;branch=z9hG4bKPj3bf79f88-7df4-4ceb-807e-1dcb4848484f\r\nFrom: \"VOYCEDialer\" <sip:02127090816@10.22.0.93>;tag=1ffb37d7-97d7-4f6e-b90b-ec69617d7711\r\nTo: <sip:05432902910@10.235.13.37>\r\nContact: <sip:asterisk@10.22.0.93:5090>\r\nCall-ID: a1cc6002-546c-4ee6-8ad3-f7fb1873c63f\r\nCSeq: 17578 INVITE\r\nAllow: OPTIONS,  REGISTER,  SUBSCRIBE,  NOTIFY,  PUBLISH,  INVITE,  ACK,  BYE,  CANCEL,  UPDATE,  PRACK,  MESSAGE,  REFER\r\nSupported: 100rel, timer, replaces, norefersub, histinfo\r\nSession-Expires: 1800\r\nMin-SE: 90\r\nX-Extension: VOYCEDialer\r\nP-Early-Media: supported\r\nMax-Forwards: 70\r\nUser-Agent: Asterisk PBX 18.14.0\r\nContent-Type: application/sdp\r\nContent-Length: 304\r\n\r\nv=0\r\no=- 1246611580 1246611580 IN IP4 10.22.0.93\r\ns=Asterisk\r\nc=IN IP4 10.22.0.93\r\nt=0 0\r\nm=audio 15156 RTP/AVP 0 8 18 101\r\na=rtpmap:0 PCMU/8000\r\na=rtpmap:8 PCMA/8000\r\na=rtpmap:18 G729/8000\r\na=fmtp:18 annexb=no\r\na=rtpmap:101 telephone-event/8000\r\na=fmtp:101 0-16\r\na=ptime:20\r\na=maxptime:150\r\na=sendrecv\r\n",
              "MessageDate": "Apr 24, 2024 12:04:35 PM",
              "SrcIpPort": "10.22.0.93:5090",
              "DstIpPort": "10.235.13.37:5060",
              "Transport": "UDP"
            },
            {
              "From": "02127090816",
              "To": "05432902910",
              "CallId": "a1cc6002-546c-4ee6-8ad3-f7fb1873c63f",
              "Request": "",
              "Response": 100,
              "Message": "SIP/2.0 100 Trying\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport=5090;received=10.22.0.93;branch=z9hG4bKPj3bf79f88-7df4-4ceb-807e-1dcb4848484f\r\nFrom: \"VOYCEDialer\" <sip:02127090816@10.22.0.93>;tag=1ffb37d7-97d7-4f6e-b90b-ec69617d7711\r\nTo: <sip:05432902910@10.235.13.37>\r\nCall-ID: a1cc6002-546c-4ee6-8ad3-f7fb1873c63f\r\nCSeq: 17578 INVITE\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:04:35 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090816",
              "To": "05432902910",
              "CallId": "a1cc6002-546c-4ee6-8ad3-f7fb1873c63f",
              "Request": "",
              "Response": 183,
              "Message": "SIP/2.0 183 Session Progress\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport=5090;received=10.22.0.93;branch=z9hG4bKPj3bf79f88-7df4-4ceb-807e-1dcb4848484f\r\nTo: <sip:05432902910@10.235.13.37>;tag=3922938275-1366022200\r\nFrom: \"VOYCEDialer\" <sip:02127090816@10.22.0.93>;tag=1ffb37d7-97d7-4f6e-b90b-ec69617d7711\r\nCall-ID: a1cc6002-546c-4ee6-8ad3-f7fb1873c63f\r\nCSeq: 17578 INVITE\r\nAllow: PUBLISH, MESSAGE, UPDATE, PRACK, SUBSCRIBE, REFER, INFO, NOTIFY, REGISTER, OPTIONS, BYE, INVITE, ACK, CANCEL\r\nContact: <sip:05432902910@10.235.13.37:5060>\r\nContent-Type: application/sdp\r\nContent-Length: 182\r\n\r\nv=0\r\no=isnet-sbc-kule2 257524137 257524138 IN IP4 10.235.13.37\r\ns=sip call\r\nc=IN IP4 10.235.13.36\r\nt=0 0\r\nm=audio 21472 RTP/AVP 0 101\r\na=rtpmap:101 telephone-event/8000\r\na=ptime:20\r\n",
              "MessageDate": "Apr 24, 2024 12:04:35 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090816",
              "To": "05432902910",
              "CallId": "a1cc6002-546c-4ee6-8ad3-f7fb1873c63f",
              "Request": "",
              "Response": 200,
              "Message": "SIP/2.0 200 OK\r\nSession-Expires: 1800;refresher=uas\r\nRequire: timer\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport=5090;received=10.22.0.93;branch=z9hG4bKPj3bf79f88-7df4-4ceb-807e-1dcb4848484f\r\nTo: <sip:05432902910@10.235.13.37>;tag=3922938275-1366022200\r\nFrom: \"VOYCEDialer\" <sip:02127090816@10.22.0.93>;tag=1ffb37d7-97d7-4f6e-b90b-ec69617d7711\r\nCall-ID: a1cc6002-546c-4ee6-8ad3-f7fb1873c63f\r\nCSeq: 17578 INVITE\r\nAllow: PUBLISH, MESSAGE, UPDATE, PRACK, SUBSCRIBE, REFER, INFO, NOTIFY, REGISTER, OPTIONS, BYE, INVITE, ACK, CANCEL\r\nContact: <sip:05432902910@10.235.13.37:5060>\r\nContent-Type: application/sdp\r\nAccept: application/sdp\r\nAllow-Events: message-summary, refer, dialog, line-seize, presence, call-info, as-feature-event, calling-name, ua-profile\r\nContent-Length: 182\r\n\r\nv=0\r\no=isnet-sbc-kule2 257524137 257524138 IN IP4 10.235.13.37\r\ns=sip call\r\nc=IN IP4 10.235.13.36\r\nt=0 0\r\nm=audio 21472 RTP/AVP 0 101\r\na=rtpmap:101 telephone-event/8000\r\na=ptime:20\r\n",
              "MessageDate": "Apr 24, 2024 12:04:46 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090816",
              "To": "05432902910",
              "CallId": "a1cc6002-546c-4ee6-8ad3-f7fb1873c63f",
              "Request": "ACK",
              "Response": 0,
              "Message": "ACK sip:05432902910@10.235.13.37:5060 SIP/2.0\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport;branch=z9hG4bKPj889ec644-5a56-4a56-8f39-0c7e6e1bfaff\r\nFrom: \"VOYCEDialer\" <sip:02127090816@10.22.0.93>;tag=1ffb37d7-97d7-4f6e-b90b-ec69617d7711\r\nTo: <sip:05432902910@10.235.13.37>;tag=3922938275-1366022200\r\nCall-ID: a1cc6002-546c-4ee6-8ad3-f7fb1873c63f\r\nCSeq: 17578 ACK\r\nMax-Forwards: 70\r\nUser-Agent: Asterisk PBX 18.14.0\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:04:46 PM",
              "SrcIpPort": "10.22.0.93:5090",
              "DstIpPort": "10.235.13.37:5060",
              "Transport": "UDP"
            },
            {
              "From": "05432902910",
              "To": "02127090816",
              "CallId": "a1cc6002-546c-4ee6-8ad3-f7fb1873c63f",
              "Request": "BYE",
              "Response": 0,
              "Message": "BYE sip:asterisk@10.22.0.93:5090 SIP/2.0\r\nMax-Forwards: 69\r\nTo: \"VOYCEDialer\" <sip:02127090816@10.22.0.93>;tag=1ffb37d7-97d7-4f6e-b90b-ec69617d7711\r\nFrom: <sip:05432902910@10.235.13.37>;tag=3922938275-1366022200\r\nCall-ID: a1cc6002-546c-4ee6-8ad3-f7fb1873c63f\r\nCSeq: 2 BYE\r\nAllow: PUBLISH, MESSAGE, UPDATE, PRACK, SUBSCRIBE, REFER, INFO, NOTIFY, REGISTER, OPTIONS, BYE, INVITE, ACK, CANCEL\r\nVia: SIP/2.0/UDP 10.235.13.37:5060;branch=z9hG4bK7b20531d018afb6464fa8117bdc9d0ac\r\nAllow-Events: message-summary, refer, dialog, line-seize, presence, call-info, as-feature-event, calling-name, ua-profile\r\nReason: X.int;reasoncode=0x00000000;add-info=05CC.0001.0001, Q.850;cause=16\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:09:02 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            }
          ],
          "SpeechRules": [
            {
              "Id": "6628c4a31cc8fe45e6d5ea30",
              "KeyWords": [
                "ödeme",
                "ödeme",
                "ödeme",
                "ödeme",
                "kredi kartı",
                "kredi kartı",
                "kredi",
                "kredi"
              ],
              "ResultDate": "2024-04-24T09:10:27.283Z",
              "Result": false
            },
            {
              "Id": "6628c47f1cc8fe45e6d5ea2c",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:10:27.288Z",
              "Result": false
            },
            {
              "Id": "65b7670df86bc254da42fd4a",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:10:27.316Z",
              "Result": false
            },
            {
              "Id": "6628c4c91cc8fe45e6d5ea3c",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:10:27.326Z",
              "Result": false
            }
          ],
          "Listens": [
            {
              "UserId": "Admin",
              "Date": "2024-04-24T09:21:35.518Z"
            }
          ]
        },
        {
          "_id": "6628cb18754252a369b45c1f",
          "Station": {
            "No": 5,
            "Extension": "02127090532",
            "IsConferenceRoom": false,
            "Inbound": true,
            "Outbound": true,
            "Speech": true,
            "Stereo": false,
            "Encrypt": false,
            "Encode": true,
            "Qm": true,
            "Name": "ethem.tumba_collecturk",
            "Description": "ethem.tumba_collecturk",
            "SwitchId": "Santral",
            "PbxId": "ethem.tumba_collecturk",
            "RecognitionServerId": "6627a93bd050e39e274de76c",
            "Model": "tr-8",
            "Organization": "collecturk",
            "OrganizationId": "6627a8ebd050e39e274de764"
          },
          "RecorderName": "Kayıt Sunucusu",
          "MachineName": "colvycstt",
          "CallId": "9958dd6f-08ea-4209-8708-0b3510388559",
          "SipCallId": "4c81ae38-72d2-4e99-960c-67a212191bbc",
          "PbxCallId": "xGha6w7tE3MeAgFBqREbY",
          "StartDate": "2024-04-24T09:03:40.000Z",
          "StopDate": "2024-04-24T09:04:23.000Z",
          "Duration": 43,
          "PhoneNumber": "03425177119",
          "Inbound": true,
          "HasVideo": false,
          "VideoCodecIn": "",
          "VideoCodecOut": "",
          "HoldCount": 0,
          "HoldDuration": 0,
          "TerminateReason": 1,
          "SipKey": "X-VoyceID",
          "SipKeyValue": "Yanlış Numara",
          "QueueName": "collecturk_queue_dunya_varlik",
          "QueueExtension": "Dünya Varlık",
          "CtiDatas": [
            {
              "PhoneNumber": "03425177119",
              "PbxId": "ethem.tumba_collecturk",
              "CtiCallId": "4c81ae38-72d2-4e99-960c-67a212191bbc",
              "Queue": "Dünya Varlık",
              "CustomDatas": [
                "CustomerNo=23592116",
                "CustomerName=TAMER DİKER"
              ],
              "Date": "2024-04-24T09:03:40.000Z",
              "CtiEvent": 0,
              "Direction": 0,
              "TerminateReason": 0,
              "ControlPhoneNumber": false,
              "MatchWithSipCallId": true
            }
          ],
          "Attachments": [],
          "MediaLog": {
            "RtpPacketCountIn": 1331,
            "RtpPacketCountOut": 2237,
            "DublicateRtpPacketCountIn": 0,
            "DublicateRtpPacketCountOut": 0,
            "LostRtpPacketCountIn": 48,
            "LostRtpPacketCountOut": 0,
            "FirstRtpPacketIn": "Apr 24, 2024 12:03:37 PM",
            "FirstRtpPacketOut": "Apr 24, 2024 12:03:39 PM",
            "LastRtpPacketIn": "Apr 24, 2024 12:04:12 PM",
            "LastRtpPacketOut": "Apr 24, 2024 12:04:23 PM",
            "CodecIn": [],
            "CodecOut": [],
            "RtpEncryptionIn": false,
            "RtpEncryptionOut": false,
            "SourcesIn": [
              {
                "IpPortLen": "10.235.13.36-21004 -> 10.22.0.93-12932-180",
                "Count": 1331,
                "FirstDate": "Apr 24, 2024 12:03:37 PM",
                "LastDate": "Apr 24, 2024 12:04:12 PM",
                "a": "10.235.13.36",
                "b": 21004,
                "c": "10.22.0.93",
                "d": 12932
              },
              {
                "IpPortLen": "10.235.13.36-21004 -> 10.22.0.93-12932-21",
                "Count": 50,
                "FirstDate": "Apr 24, 2024 12:03:40 PM",
                "LastDate": "Apr 24, 2024 12:04:12 PM",
                "a": "10.235.13.36",
                "b": 21004,
                "c": "10.22.0.93",
                "d": 12932
              }
            ],
            "SourcesOut": [
              {
                "IpPortLen": "10.22.0.93-12932 -> 10.235.13.36-21004-180",
                "Count": 2240,
                "FirstDate": "Apr 24, 2024 12:03:39 PM",
                "LastDate": "Apr 24, 2024 12:04:23 PM",
                "a": "10.22.0.93",
                "b": 12932,
                "c": "10.235.13.36",
                "d": 21004
              }
            ]
          },
          "SignalingLogs": [
            {
              "From": "02127090532",
              "To": "03425177119",
              "CallId": "4c81ae38-72d2-4e99-960c-67a212191bbc",
              "Request": "INVITE",
              "Response": 0,
              "Message": "INVITE sip:03425177119@10.235.13.37:5060 SIP/2.0\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport;branch=z9hG4bKPj0b4da266-e0d0-4900-810c-cd33d3f8f92e\r\nFrom: \"VOYCEDialer\" <sip:02127090532@10.22.0.93>;tag=b8e75f21-b5bd-4270-9e4c-68595f91f47d\r\nTo: <sip:03425177119@10.235.13.37>\r\nContact: <sip:asterisk@10.22.0.93:5090>\r\nCall-ID: 4c81ae38-72d2-4e99-960c-67a212191bbc\r\nCSeq: 7989 INVITE\r\nAllow: OPTIONS,  REGISTER,  SUBSCRIBE,  NOTIFY,  PUBLISH,  INVITE,  ACK,  BYE,  CANCEL,  UPDATE,  PRACK,  MESSAGE,  REFER\r\nSupported: 100rel, timer, replaces, norefersub, histinfo\r\nSession-Expires: 1800\r\nMin-SE: 90\r\nX-Extension: VOYCEDialer\r\nP-Early-Media: supported\r\nMax-Forwards: 70\r\nUser-Agent: Asterisk PBX 18.14.0\r\nContent-Type: application/sdp\r\nContent-Length: 304\r\n\r\nv=0\r\no=- 1115431044 1115431044 IN IP4 10.22.0.93\r\ns=Asterisk\r\nc=IN IP4 10.22.0.93\r\nt=0 0\r\nm=audio 12932 RTP/AVP 0 8 18 101\r\na=rtpmap:0 PCMU/8000\r\na=rtpmap:8 PCMA/8000\r\na=rtpmap:18 G729/8000\r\na=fmtp:18 annexb=no\r\na=rtpmap:101 telephone-event/8000\r\na=fmtp:101 0-16\r\na=ptime:20\r\na=maxptime:150\r\na=sendrecv\r\n",
              "MessageDate": "Apr 24, 2024 12:03:31 PM",
              "SrcIpPort": "10.22.0.93:5090",
              "DstIpPort": "10.235.13.37:5060",
              "Transport": "UDP"
            },
            {
              "From": "02127090532",
              "To": "03425177119",
              "CallId": "4c81ae38-72d2-4e99-960c-67a212191bbc",
              "Request": "",
              "Response": 100,
              "Message": "SIP/2.0 100 Trying\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport=5090;received=10.22.0.93;branch=z9hG4bKPj0b4da266-e0d0-4900-810c-cd33d3f8f92e\r\nFrom: \"VOYCEDialer\" <sip:02127090532@10.22.0.93>;tag=b8e75f21-b5bd-4270-9e4c-68595f91f47d\r\nTo: <sip:03425177119@10.235.13.37>\r\nCall-ID: 4c81ae38-72d2-4e99-960c-67a212191bbc\r\nCSeq: 7989 INVITE\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:03:31 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090532",
              "To": "03425177119",
              "CallId": "4c81ae38-72d2-4e99-960c-67a212191bbc",
              "Request": "",
              "Response": 180,
              "Message": "SIP/2.0 180 Ringing\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport=5090;received=10.22.0.93;branch=z9hG4bKPj0b4da266-e0d0-4900-810c-cd33d3f8f92e\r\nTo: <sip:03425177119@10.235.13.37>;tag=3922938211-962563206\r\nFrom: \"VOYCEDialer\" <sip:02127090532@10.22.0.93>;tag=b8e75f21-b5bd-4270-9e4c-68595f91f47d\r\nCall-ID: 4c81ae38-72d2-4e99-960c-67a212191bbc\r\nCSeq: 7989 INVITE\r\nAllow: PUBLISH, MESSAGE, UPDATE, PRACK, SUBSCRIBE, REFER, INFO, NOTIFY, REGISTER, OPTIONS, BYE, INVITE, ACK, CANCEL\r\nContact: <sip:03425177119@10.235.13.37:5060>\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:03:31 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090532",
              "To": "03425177119",
              "CallId": "4c81ae38-72d2-4e99-960c-67a212191bbc",
              "Request": "",
              "Response": 200,
              "Message": "SIP/2.0 200 OK\r\nSession-Expires: 1800;refresher=uas\r\nRequire: timer\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport=5090;received=10.22.0.93;branch=z9hG4bKPj0b4da266-e0d0-4900-810c-cd33d3f8f92e\r\nTo: <sip:03425177119@10.235.13.37>;tag=3922938211-962563206\r\nFrom: \"VOYCEDialer\" <sip:02127090532@10.22.0.93>;tag=b8e75f21-b5bd-4270-9e4c-68595f91f47d\r\nCall-ID: 4c81ae38-72d2-4e99-960c-67a212191bbc\r\nCSeq: 7989 INVITE\r\nAllow: PUBLISH, MESSAGE, UPDATE, PRACK, SUBSCRIBE, REFER, INFO, NOTIFY, REGISTER, OPTIONS, BYE, INVITE, ACK, CANCEL\r\nContact: <sip:03425177119@10.235.13.37:5060>\r\nContent-Type: application/sdp\r\nAccept: application/sdp\r\nAllow-Events: message-summary, refer, dialog, line-seize, presence, call-info, as-feature-event, calling-name, ua-profile\r\nContent-Length: 182\r\n\r\nv=0\r\no=isnet-sbc-kule2 257460020 257460021 IN IP4 10.235.13.37\r\ns=sip call\r\nc=IN IP4 10.235.13.36\r\nt=0 0\r\nm=audio 21004 RTP/AVP 8 101\r\na=rtpmap:101 telephone-event/8000\r\na=ptime:20\r\n",
              "MessageDate": "Apr 24, 2024 12:03:37 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090532",
              "To": "03425177119",
              "CallId": "4c81ae38-72d2-4e99-960c-67a212191bbc",
              "Request": "ACK",
              "Response": 0,
              "Message": "ACK sip:03425177119@10.235.13.37:5060 SIP/2.0\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport;branch=z9hG4bKPjbe4be978-3a3b-4d68-863d-14ae49a9625b\r\nFrom: \"VOYCEDialer\" <sip:02127090532@10.22.0.93>;tag=b8e75f21-b5bd-4270-9e4c-68595f91f47d\r\nTo: <sip:03425177119@10.235.13.37>;tag=3922938211-962563206\r\nCall-ID: 4c81ae38-72d2-4e99-960c-67a212191bbc\r\nCSeq: 7989 ACK\r\nMax-Forwards: 70\r\nUser-Agent: Asterisk PBX 18.14.0\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:03:37 PM",
              "SrcIpPort": "10.22.0.93:5090",
              "DstIpPort": "10.235.13.37:5060",
              "Transport": "UDP"
            },
            {
              "From": "03425177119",
              "To": "02127090532",
              "CallId": "4c81ae38-72d2-4e99-960c-67a212191bbc",
              "Request": "INVITE",
              "Response": 0,
              "Message": "INVITE sip:asterisk@10.22.0.93:5090 SIP/2.0\r\nMax-Forwards: 69\r\nSession-Expires: 1800;refresher=uac\r\nMin-SE: 600\r\nSupported: timer, 100rel\r\nTo: \"VOYCEDialer\" <sip:02127090532@10.22.0.93>;tag=b8e75f21-b5bd-4270-9e4c-68595f91f47d\r\nFrom: <sip:03425177119@10.235.13.37>;tag=3922938211-962563206\r\nCall-ID: 4c81ae38-72d2-4e99-960c-67a212191bbc\r\nCSeq: 2 INVITE\r\nAllow: PUBLISH, MESSAGE, UPDATE, PRACK, SUBSCRIBE, REFER, INFO, NOTIFY, REGISTER, OPTIONS, BYE, INVITE, ACK, CANCEL\r\nVia: SIP/2.0/UDP 10.235.13.37:5060;branch=z9hG4bK8ac6e01ac497aa6eea343c53ab653fed\r\nContact: <sip:03425177119@10.235.13.37:5060>\r\nContent-Type: application/sdp\r\nAccept: application/sdp\r\nAllow-Events: message-summary, refer, dialog, line-seize, presence, call-info, as-feature-event, calling-name, ua-profile\r\nContent-Length: 182\r\n\r\nv=0\r\no=isnet-sbc-kule2 257460020 257460022 IN IP4 10.235.13.37\r\ns=sip call\r\nc=IN IP4 10.235.13.36\r\nt=0 0\r\nm=audio 21004 RTP/AVP 8 101\r\na=rtpmap:101 telephone-event/8000\r\na=ptime:20\r\n",
              "MessageDate": "Apr 24, 2024 12:04:12 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "03425177119",
              "To": "02127090532",
              "CallId": "4c81ae38-72d2-4e99-960c-67a212191bbc",
              "Request": "",
              "Response": 200,
              "Message": "SIP/2.0 200 OK\r\nVia: SIP/2.0/UDP 10.235.13.37:5060;rport=5060;received=10.235.13.37;branch=z9hG4bK8ac6e01ac497aa6eea343c53ab653fed\r\nCall-ID: 4c81ae38-72d2-4e99-960c-67a212191bbc\r\nFrom: <sip:03425177119@10.235.13.37>;tag=3922938211-962563206\r\nTo: \"VOYCEDialer\" <sip:02127090532@10.22.0.93>;tag=b8e75f21-b5bd-4270-9e4c-68595f91f47d\r\nCSeq: 2 INVITE\r\nSession-Expires: 1800;refresher=uac\r\nRequire: timer\r\nContact: <sip:asterisk@10.22.0.93:5090>\r\nAllow: OPTIONS,  REGISTER,  SUBSCRIBE,  NOTIFY,  PUBLISH,  INVITE,  ACK,  BYE,  CANCEL,  UPDATE,  PRACK,  MESSAGE,  REFER\r\nSupported: 100rel, timer, replaces, norefersub\r\nServer: Asterisk PBX 18.14.0\r\nContent-Type: application/sdp\r\nContent-Length: 233\r\n\r\nv=0\r\no=- 1115431044 1115431045 IN IP4 10.22.0.93\r\ns=Asterisk\r\nc=IN IP4 10.22.0.93\r\nt=0 0\r\nm=audio 12932 RTP/AVP 8 101\r\na=rtpmap:8 PCMA/8000\r\na=rtpmap:101 telephone-event/8000\r\na=fmtp:101 0-16\r\na=ptime:20\r\na=maxptime:150\r\na=sendrecv\r\n",
              "MessageDate": "Apr 24, 2024 12:04:12 PM",
              "SrcIpPort": "10.22.0.93:5090",
              "DstIpPort": "10.235.13.37:5060",
              "Transport": "UDP"
            },
            {
              "From": "03425177119",
              "To": "02127090532",
              "CallId": "4c81ae38-72d2-4e99-960c-67a212191bbc",
              "Request": "ACK",
              "Response": 0,
              "Message": "ACK sip:asterisk@10.22.0.93:5090 SIP/2.0\r\nMax-Forwards: 69\r\nTo: \"VOYCEDialer\" <sip:02127090532@10.22.0.93>;tag=b8e75f21-b5bd-4270-9e4c-68595f91f47d\r\nFrom: <sip:03425177119@10.235.13.37>;tag=3922938211-962563206\r\nCall-ID: 4c81ae38-72d2-4e99-960c-67a212191bbc\r\nCSeq: 2 ACK\r\nVia: SIP/2.0/UDP 10.235.13.37:5060;branch=z9hG4bK5dd8035333d1b5932dee2992c5f3a4d6\r\nContact: <sip:03425177119@10.235.13.37:5060>\r\nAllow-Events: message-summary, refer, dialog, line-seize, presence, call-info, as-feature-event, calling-name, ua-profile\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:04:12 PM",
              "SrcIpPort": "10.235.13.37:5060",
              "DstIpPort": "10.22.0.93:5090",
              "Transport": "UDP"
            },
            {
              "From": "02127090532",
              "To": "03425177119",
              "CallId": "4c81ae38-72d2-4e99-960c-67a212191bbc",
              "Request": "BYE",
              "Response": 0,
              "Message": "BYE sip:03425177119@10.235.13.37:5060 SIP/2.0\r\nVia: SIP/2.0/UDP 10.22.0.93:5090;rport;branch=z9hG4bKPj2be3b0f5-fabd-4fe7-9025-0e42bec760b1\r\nFrom: \"VOYCEDialer\" <sip:02127090532@10.22.0.93>;tag=b8e75f21-b5bd-4270-9e4c-68595f91f47d\r\nTo: <sip:03425177119@10.235.13.37>;tag=3922938211-962563206\r\nCall-ID: 4c81ae38-72d2-4e99-960c-67a212191bbc\r\nCSeq: 7990 BYE\r\nReason: Q.850;cause=16\r\nMax-Forwards: 70\r\nUser-Agent: Asterisk PBX 18.14.0\r\nContent-Length: 0\r\n\r\n",
              "MessageDate": "Apr 24, 2024 12:04:23 PM",
              "SrcIpPort": "10.22.0.93:5090",
              "DstIpPort": "10.235.13.37:5060",
              "Transport": "UDP"
            }
          ],
          "SpeechRules": [
            {
              "Id": "6628c4a31cc8fe45e6d5ea30",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:05:23.585Z",
              "Result": false
            },
            {
              "Id": "6628c47f1cc8fe45e6d5ea2c",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:05:23.587Z",
              "Result": false
            },
            {
              "Id": "65b7670df86bc254da42fd4a",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:05:23.599Z",
              "Result": false
            },
            {
              "Id": "6628c4c91cc8fe45e6d5ea3c",
              "KeyWords": [],
              "ResultDate": "2024-04-24T09:05:23.606Z",
              "Result": false
            }
          ]
        }
      ],
      chartOptionsComputedData: [
        [
          {
            "name": "data",
            "data": [
              76,
              35,
              78,
              11,
              42,
              64,
              86
            ]
          }
        ],
        [
          {
            "name": "data1",
            "data": [
              9,
              7,
              96,
              74,
              54,
              47,
              37
            ]
          }
        ],
        [
          {
            "name": "data2",
            "data": [
              82,
              81,
              76,
              73,
              62,
              38,
              12
            ]
          }
        ],
        [
          {
            "name": "data3",
            "data": [
              32,
              96,
              80,
              73,
              39,
              88,
              40
            ]
          }
        ],
        [
          {
            "name": "data4",
            "data": [
              56,
              16,
              82,
              92,
              33,
              14,
              24
            ]
          }
        ],
        [
          {
            "name": "data5",
            "data": [
              52,
              39,
              62,
              1,
              42,
              32,
              92
            ]
          }
        ]
      ],

      chartOptionsComputed: {
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
          },
        },
        chart: {
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          width: 2.5,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 0.9,
            opacityFrom: 0.5,
            opacityTo: 0.2,
            stops: [0, 80, 100],
          },
        },
        xaxis: {
          type: 'numeric',
          lines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: { show: false },
        },
        yaxis: [
          {
            y: 0,
            offsetX: 0,
            offsetY: 0,
            padding: {
              left: 0,
              right: 0,
            },
          },
        ],
        tooltip: {
          x: { show: false },
        },
        theme: {
          monochrome: {
            enabled: true,
            color: $themeColors.secondary,
            shadeTo: 'light',
            shadeIntensity: 0.65,
          },
        },
      },
      statisticsItems2: [
        {
          icon: 'ActivityIcon',
          color: 'light-success',
          title: '% 56',
          subtitle: 'Analiz Edilen',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ClockIcon',
          color: 'light-info',
          title: '% 12',
          subtitle: 'Ortalama Çağrı Süresi',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '1249',
          subtitle: 'Otomatik Kalite Puanı',
          customClass: '',
        },
      ],
      statisticsItems: [
        {
          icon: 'MessageCircleIcon',
          color: 'light-warning',
          title: '% 56',
          subtitle: 'Üst Üste Konuşma Oranı',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'MoreHorizontalIcon',
          color: 'light-secondary',
          title: '% 12',
          subtitle: 'Sessizlik Oranı',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'XOctagonIcon',
          color: 'light-danger',
          title: '1249',
          subtitle: 'Temsilci Söz Kesme Sayısı',
          customClass: '',
        },
      ],
      projects: [],
      queues: [],
      selected_queue_group: [],
      queue_groups: [],
      selected_queues: [],
      selected_project: "",
      visible_users: [],
      finish_codes: [],
      dashboard_data: [],
      bar_charts: [],
      full_screen: false,
      fields: [
        { key: "Agent", label: globalThis._lang("t_agent"), sortable: true, tdClass: 'text-nowrap' },
        {
          key: "Productivity",
          label: globalThis._lang("t_productivity"),
          sortable: true,
        },
        {
          key: "Inbound",
          label: globalThis._lang("t_inbound"),
          sortable: true,
        },
        {
          key: "Outbound",
          label: globalThis._lang("t_outbound"),
          sortable: true,
        },
        { key: "Dialer", label: globalThis._lang("t_dialer"), sortable: true },
        {
          key: "MissedCalls",
          label: globalThis._lang("t_missedCall"),
          sortable: true,
        },
        // { key: 'AvgCallTime', label: globalThis._lang('t_avgCallTime'), sortable: true },
      ],
      tableData: [],
      speechRegions: [],
      searchQuery: "",
      interval: "",
      DataToShow: {},
      selected_record: null,
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  methods: {
    isWordPlaying(word) {
      return this.currentTime >= word.Start && this.currentTime < word.End;
    },
    processDataSet(dataSet) {
      this.speechRegions = dataSet.Channels.flatMap(channel => channel.SpeechRegions);
    },
    closeSound() {
      if (this.displaySound) {
        this.displaySound = false;
        var myDiv = document.getElementById(`waveform`);
        myDiv.innerHTML = null;
        this.wavesurfer = null;
        this.currentTime = 0;
      }

    },

    formatTime(seconds) {
      let minutes = Math.floor(seconds / 60);
      let remainingSeconds = seconds % 60;

      minutes = minutes < 10 ? '0' + minutes : minutes;
      remainingSeconds = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;

      return minutes + ":" + remainingSeconds;
    },
    setSoundSpeed(speed) {
      if (this.wavesurfer) {
        this.wavesurfer.setPlaybackRate(speed, true);
        this.wavesurfer.play();
      }
    },

    getStartEndDates(period) {
      const now = new Date();
      let start, end;

      switch (period) {
        case 'lastOneHour':
          start = new Date(now.getTime() - 60 * 60 * 1000);
          end = now;
          break;
        case 'lastThreeHours':
          start = new Date(now.getTime() - 3 * 60 * 60 * 1000);
          end = now;
          break;
        case 'today':
          start = new Date(now.setHours(0, 0, 0, 0));
          end = new Date(now.setHours(23, 59, 59, 999));
          break;
        case 'yesterday':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
          end = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 23, 59, 59, 999);
          break;
        case 'twoDaysAgo':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 2);
          end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
          break;
        case 'threeDaysAgo':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 3);
          end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
          break;
        case 'fourDaysAgo':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 4);
          end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
          break;
        case 'fiveDaysAgo':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 5);
          end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
          break;
        case 'sixDaysAgo':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6);
          end = new Date(now.getFullYear(), now.Month(), now.getDate(), 23, 59, 59, 999);
          break;
        case 'lastWeek':
          const dayOfWeek = now.getDay();
          start = new Date(now.setDate(now.getDate() - dayOfWeek - 6));
          start.setHours(0, 0, 0, 0);
          end = new Date(start.getTime() + 6 * 24 * 60 * 60 * 1000);
          end.setHours(23, 59, 59, 999);
          break;
        case 'thisMonth':
          start = new Date(now.getFullYear(), now.getMonth(), 1);
          end = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999);
          break;
        default:
          throw new Error('Invalid period');
      }

      return { start: this.formatDate(start), end: this.formatDate(end) };
    },
    formatDate(date) {
      // return date;
      // return date.toISOString().replace('T', ' ').slice(0, 19);
      return date.toISOString();
    },

    async fetchRecords() {
      let date_range = this.getStartEndDates(this.selected_date_filter_type);
      var response = (await this.$http_ex.get(`${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'integration-callexper')}/callexper/v1/GetRecords/${date_range.start}/${date_range.end}/${this.currentPage}/${this.perPage}`)).data;
      console.log(response);
      this.records = response.data.map(e => { return { ...e, StationName: e.Station.Name } });
      this.totalRows = response.recordsTotal;
    },

    onClickKeywordCategory(data) {
      if (data.Name == this.selected_keyword_category) {
        this.selected_keyword_category = null;
        return;
      }
      this.selected_keyword_category = data.Name;

    },
    base64ToBlob(base64, mimeType) {
      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: mimeType });
    },
    checkKeywordCategory(word) {
      for (const _word of word.split(' ')) {
        let object = this.SpeechRules.filter(e => e.Result == true).find(r => r.KeyWords.includes(_word));
        console.log("checkKeywordCategory", _word, object);
        if (object) {
          console.log("GELDİ")
          return true
          // return this.dashboard_data["ByCategory"][object.Id].Name
        }
      }

      return false;

    },
    async load_sound(item) {
      try {
        this.is_progress = true;
        this.closeSound();
        this.selected_record = item;
        console.log("item", item);
        let _month = new Date(item.StartDate).getMonth()
        var response = (await this.$http_ex.get(`${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'integration-callexper')}/callexper/v1/GetDetails/${item._id}/${_month}`)).data;
        console.log("response", response);
        this.SpeechRules = response.SpeechRules;

        this.messages = [];
        this.messages = response.Speech.Channels[0].SpeechRegions.map(e => { return { ...e, type: 'customer' } });
        this.messages = [...this.messages, ...response.Speech.Channels[1].SpeechRegions.map(e => { return { ...e, type: 'agent' } })];
        this.messages = this.messages.sort((a, b) => a.Start - b.Start);

        this.displaySound = !this.displaySound;
        if (this.displaySound) {
          this.wavesurfer = WaveSurfer.create({

            container: '#waveform',
            waveColor: '#F0F0F1',
            progressColor: $themeColors.primary,
            // url: 'https://www2.cs.uic.edu/~i101/SoundFiles/BabyElephantWalk60.wav',
            url: response.RecordingFileName,
            // url: 'https://recordings.wrtcdev.voyce.cc/recordings/2023/08/19/voyce/out-1692430598.369-5445226155.ogg',
            splitChannels: [
              {
                waveColor: '#F0F0F1',
                progressColor: $themeColors.secondary,
              },
              {
                waveColor: '#F0F0F1',
                progressColor: $themeColors.primary,
              },
            ],
            // Set a bar width
            "height": 60,

            barWidth: 4,
            // Optionally, specify the spacing between bars
            barGap: 3,
            // And the bar radius
            barRadius: 8,
          })
          // let audio = new Audio();
          // audio.src = response.RecordingFile;
          // this.wavesurfer.load(audio);
          // this.wavesurfer.loadBlob(this.base64ToArrayBuffer(response.RecordingFile));
          // const base64String = response.RecordingFile;
          // const audioBlob = this.base64ToBlob(base64String, 'audio/wav');
          // this.wavesurfer.loadBlob(audioBlob);

          console.log("wavesurfer", this.wavesurfer)

          this.wavesurfer.once('interaction', () => {
            console.log("interaction")
            this.wavesurfer.play()
          })


          this.wavesurfer.on('audioprocess', (currentTime) => this.currentTime = currentTime);
        } else {
          var myDiv = document.getElementById("waveform");
          myDiv.innerHTML = null;
          this.wavesurfer = null;
          this.currentTime = 0;
        }
      } catch (error) {
      } finally {
        this.is_progress = false;
      }

    },
    date_filter_type_changed() {
      if (this.selected_date_filter_type == "today") {
        this.filter_values.startDate = new Date(
          new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
          3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
        this.filter_values.endDate = new Date(
          new Date(new Date().setHours(23, 59, 59, 0)).getTime() +
          3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
      }
      if (this.selected_date_filter_type == "yesterday") {
        this.filter_values.startDate = new Date(
          new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
          3 * 60 * 60 * 1000 -
          24 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
        this.filter_values.endDate = new Date(
          new Date(new Date().setHours(23, 59, 59, 0)).getTime() +
          3 * 60 * 60 * 1000 -
          24 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
      }
      if (this.selected_date_filter_type == "last_7_days") {
        this.filter_values.startDate = new Date(
          new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
          3 * 60 * 60 * 1000 -
          7 * 24 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
        this.filter_values.endDate = new Date(
          new Date(new Date().setHours(23, 59, 59, 0)).getTime() +
          3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
      }
      if (this.selected_date_filter_type == "last_30_days") {
        this.filter_values.startDate = new Date(
          new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
          3 * 60 * 60 * 1000 -
          30 * 24 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
        this.filter_values.endDate = new Date(
          new Date(new Date().setHours(23, 59, 59, 0)).getTime() +
          3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
      }
      if (this.selected_date_filter_type == "this_month") {
        let date = new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        this.filter_values.startDate = new Date(
          new Date(firstDay).setHours(0, 0, 0, 0) + 3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
        this.filter_values.endDate = new Date(
          new Date(lastDay).setHours(23, 59, 59, 0) + 3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
      }
      if (this.selected_date_filter_type == "last_month") {
        let date = new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth(), 0);

        this.filter_values.startDate = new Date(
          new Date(firstDay).setHours(0, 0, 0, 0) + 3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
        this.filter_values.endDate = new Date(
          new Date(lastDay).setHours(23, 59, 59, 0) + 3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
      }
      this.RefreshDataToShow();
    },
    async RefreshDataToShow() {
      await this.GetDashboardData();
      await this.fetchRecords();

      if (this.dashboard_data["ByDate"][this.selected_date_filter_type]) {
        this.DataToShow = this.dashboard_data["ByDate"][this.selected_date_filter_type];
        for (let category of this.DataToShow.Categories) {
          if (category.Result.length > 0) {
            category.Result = category.Result.filter(m => {
              if (Array.isArray(m)) {
                return m.length > 0
              }
              return true;
            })
          }
        }

        this.DataToShow.Categories = this.DataToShow.Categories.filter(category => category.Result.length > 0);
        this.statisticsItems[0].title = "% " + parseFloat((this.DataToShow.avgcrosstalkduration / this.DataToShow.avgduration * 100) || 0).toFixed(1);
        this.statisticsItems[1].title = "% " + parseFloat((this.DataToShow.avgsilenceduration / this.DataToShow.avgduration * 100) || 0).toFixed(1);
        this.statisticsItems[2].title = parseFloat(this.DataToShow.avginterruptcount || 0).toFixed(1);

        this.statisticsItems2[0].title = this.DataToShow.count || 0;
        this.statisticsItems2[1].title = parseFloat(this.DataToShow.avgduration || 0).toFixed(1);
        this.statisticsItems2[2].title = this.DataToShow.avgqualitypoint || 0;
        console.log(this.DataToShow.Result);
      }

    },
    formatSeconds(seconds) {
      var minutes = Math.floor(seconds / 60);
      var remainingSeconds = seconds % 60;

      // Pad the minutes and seconds with leading zeros, if required
      var paddedMinutes = String(minutes).padStart(1, "0");
      var paddedSeconds = String(remainingSeconds).padStart(1, "0");

      if (minutes > 0) {
        return `${paddedMinutes} min ${paddedSeconds} sec`;
      } else {
        return `${paddedSeconds} sec`;
      }
    },
    queue_group_changed() {
      // console.log("queue_group_changed", this.selected_queue_group)
      let queues = [];
      for (const item of this.selected_queue_group) {
        queues = [...queues, ...item.queues.map((e) => e.internal_name)];
      }
      // console.log("queues", queues)
      this.selected_queues = [];
      this.selected_queues = Array.from(
        new Set([...this.selected_queues, ...queues])
      );
      // this.get_live_agent();
    },
    get_users: async function () {
      var response = (await this.$http_in.get(`spv/v1/User`)).data;

      for (const row of response) {
        this.visible_users[row.identifier] = row.is_visible_in_live_watch;
      }
    },
    GetQueues: async function () {
      var response = (await this.$http_in.get(`spv/v1/Queue`)).data;
      // console.log('queues', response);
      let p_queues = globalThis.permissions["queue"];
      let default_push = this.selected_queues.length > 0 ? false : true;
      this.all_queues = response;
      for (const item of response) {
        if (p_queues.includes(item.internal_name)) {
          this.queues.push(item);
          this.merged_queues.push({
            ...item,
            _type: 'queue'
          })

          if (default_push) {
            this.selected_merged_queues.push({ ...item, _type: 'queue' });
            // this.selected_queues.push(item.internal_name);
          }
        }
      }
    },
    GetQueueGroup: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/QueueGroup`)).data;
      // console.log('queues', response);
      // let p_queues = globalThis.permissions["queue"]

      this.queue_groups = response;
      let _queue_group = response.map(function (obj) {
        return { ...obj, _type: "queue_group" };
      });
      this.merged_queues = [...this.merged_queues, ..._queue_group];


      // for (const item of response) {
      //   if (p_queues.includes(item.internal_name)) {
      //     this.queues.push(item);
      //     this.selected_queues.push(item.internal_name);
      //   }
      // }
    },
    queue_group_changed() {
      // console.log("queue_group_changed", this.selected_queue_group)
      let queues = [];
      for (const item of this.selected_queue_group) {
        queues = [...queues, ...item.queues.map((e) => e.internal_name)];
      }
      // console.log("queues", queues)

      this.selected_queues = Array.from(new Set([...this.selected_queues, ...queues]));
      this.getLiveQueue();
      this.getCallBackSuccessCount();
    },
    GetProjects: async function () {
      var response = (await this.$http_in.get(`spv/v1/Project`)).data;

      this.projects = response;
    },
    GetDashboardData: async function () {
      try {
        var response = (await this.$http_ex.get(`${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'integration-callexper')}/callexper/v1/Dashboard/${this.selected_date_filter_type}`)).data;
        this.dashboard_data = response;
      } catch (error) {

      }

    }
  },
  mounted: async function () {

    try {
      let _self = this;
      this.is_progress = true;
      this.finish_codes = globalThis.finish_codes;
      await this.get_users();

      await this.GetQueues();
      await this.GetQueueGroup();

      await this.RefreshDataToShow();
      await this.fetchRecords();
    } catch (error) {

    } finally {
      this.is_progress = false;

    }

    // await this.GetProjects();
    // await this.get_live_agent();

    // await this.GetDashboardData();

    // this.interval = setInterval(() => {
    //   _self.get_inbound_statistics();
    //   _self.get_live_agent();
    // }, 60000);
  },
  beforeDestroy() {
    // localStorage.setItem("dashboardData", JSON.stringify(this.$data));

    // clearInterval(this.interval);
  },
};
</script>

<style>
/* @import "@core/scss/vue/libs/vue-flatpicker.scss"; */
#waveform {
  cursor: pointer;
  /* position: relative; */
}
#hover {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  pointer-events: none;
  height: 100%;
  width: 0;
  mix-blend-mode: overlay;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  transition: opacity 0.2s ease;
}
#waveform:hover #hover {
  opacity: 1;
}
#time,
#duration {
  position: absolute;
  z-index: 11;
  top: 50%;
  margin-top: -1px;
  transform: translateY(-50%);
  font-size: 11px;
  background: rgba(0, 0, 0, 0.75);
  padding: 2px;
  color: #ddd;
}
#time {
  left: 0;
}
#duration {
  right: 0;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
}

.highlight {
  background-color: yellow;
}
</style>
